/// <reference path="./../../../_reference.ts"/>
module Apex.Dictionary.Controllers {

	export interface IProdPpImportScope extends angular.IScope {
		events: {
			download: Function,
			checkFile: Function,
			saveDocument: Function
		},
		other: {
			uploadLocation: string,
			fileAbsent:boolean
		}
		closeForm: Function,
		changed:Function
	}


	export class ProdPPImportModal extends Core.Controller {
		public static $inject = [
			Globals.scope, "data", "dictionaryService", Globals.modalInstance, Globals.toaster, Globals.http
		];
		constructor(private $scope: IProdPpImportScope, private data, private dictionaryService: Dictionary.Services.Dictionary, $modalInstance, private toaster, private $http: angular.IHttpService) {
			super(arguments, ProdPPImportModal.$inject);
			$scope.events = {
				download: this.download,
				checkFile: this.checkFile,
				saveDocument: this.saveDocument
			};
			$scope.other = {
				uploadLocation: dictionaryService.filterUrl("/Common/Dictionary/Goods/Document", null),
				fileAbsent:true
			};
			$scope.closeForm = () => {
				$modalInstance.close();
			};
			$scope.changed = ()=>{
				Core.Utils.Helper.safeApply($scope, () => {
					$scope.other.fileAbsent = false;
				});
			}

		}

		saveDocument = () => {
			var fd = new FormData();
			var item = $("#filesinput")[0] as HTMLInputElement;
			fd.append("upload", item.files[0]);
			
			kendo.ui.progress($('.modal-content'), true);
			this.$http.post(
				this.$scope.other.uploadLocation,
				fd,
				{
					transformRequest: angular.identity,
					headers: { 'Content-Type': undefined },
					//responseType: "arraybuffer"
				}
			).then((success)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.$scope.closeForm();
				this.toaster.pop("success", "", "ფაილი წარმატებით აიტვირთა!");
			}, (error)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.toaster.pop("error", "შეცდომა", JSON.stringify(error || ""));
			});
		}



		checkFile = () => {
			var fd = new FormData();
			var item = $("#filesinput")[0] as HTMLInputElement;
			fd.append("upload", item.files[0]); 
			kendo.ui.progress($('.modal-content'), true);
			this.$http.post(
				this.$scope.other.uploadLocation + "/Check",
				fd,
				{
					transformRequest: angular.identity,
					headers: { 'Content-Type': undefined },
					responseType: "arraybuffer"
				}
			).then((success)=>{
				kendo.ui.progress($('.modal-content'), false);
				if (success.status == 204) {
					this.toaster.pop("success", "", "ფაილი სწორია!");
				} else {
					this.saveFile(success.data, "result.xlsx");
					this.toaster.pop("warning", "", "ფაილი შეიცავს შეცდომებს!");
				}
			}, (error)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.toaster.pop("error", "", JSON.stringify(error || ""));
			});
		}

		download = () => {
			 kendo.ui.progress($('.modal-content'), true);
			this.$http({
				url: this.$scope.other.uploadLocation,
				method: "GET",
				headers: {
					'Content-type': "application/json"
				},
				responseType: "arraybuffer"
			}).then((success)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.saveFile(success.data, "result.xlsx");	
			}, (error)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.toaster.pop("error", "შეცდომა", error.Message);	
			});
		}

		private saveFile = (content, filename, contentType: string = null) => {
			if (!contentType) contentType = "application/octet-stream";
			var a = <any>document.createElement("a");
			var blob = new Blob([content], { 'type': contentType });
			a.href = window.URL.createObjectURL(blob);
			a.target = "_blank";
			a.download = filename;
			a.style = "visibility:hidden";
			document.body.appendChild(a);
			a.click();
			setTimeout(() => { document.body.removeChild(a) }, 500);
		}
	}
}