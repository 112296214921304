/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Countries extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $timeout) {
            super(arguments, Countries.$inject);
            $scope.model = {
                selectedCountry: {},
                selectedCity: {}
            }
            $scope.editMoode = false;
            $scope.editMode = false;
            $scope.canDelete = false;
            $scope.components = {
                countriesGrid: this.initCountriesGrid(),
                citiesGrid: this.initCitiesGrid(),
                events: {
                    countries: {
                        add: this.addCountry,
                        edit: this.editCountry,
                        delete: this.deleteCountry,
                        refresh: () => {
                            $scope.components.showRefreshInfo = false;
                            $scope.components.countriesGrid.gridView.showLoading();

                            $timeout(() => {
                                $scope.components.countriesGrid.gridView.refresh();
                            });


                        }
                    },
                    cities: {
                        add: this.addCity,
                        edit: this.editCity,
                        delete: this.deleteCity,
                        refresh: () => {
                            $scope.components.citiesGrid.gridView.refresh(); 
                        }
                    },
                    refresh: () => {
                        $scope.components.events.countries.refresh();
                        $scope.components.events.cities.refresh();
                    }
                },
                showRefreshInfo: true
            }

            this.onCompanyChange = (newCompany, oldCompany) => {
                $scope.components.events.refresh();
            }
        }


        addCountry = () => { 
            this.openCountryModal({});
        }
        addCity = () => {
            this.openCityModal({}, this.$scope.model.selectedCountry);
        }

        editCountry = () => {
            this.$scope.model.selectedCountry = this.$scope.components.countriesGrid.gridView.selectedItem();
            this.openCountryModal(this.$scope.model.selectedCountry);
        }

        editCity = () => {
            this.$scope.model.selectedCity = this.$scope.components.citiesGrid.gridView.selectedItem();
            this.openCityModal(this.$scope.model.selectedCity, null);
        }

        deleteCountry = () => {
            this.$scope.model.selectedCountry = this.$scope.components.countriesGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteCountry(this.$scope.model.selectedCountry.CountryId).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });
        }
        deleteCity = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            var selectedCity = this.$scope.components.citiesGrid.gridView.selectedItem();

            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteCity(selectedCity.CityId).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.editMode = false;
                        (<Apex.Components.IApxGrid>this.$scope.components.citiesGrid).dataSource.remove(selectedCity);
                    }
                });
            });
        }

        openCountryModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("country.modal.html", "countryModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.countries.refresh();
                }
            });
        }

        openCityModal = (city, country) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var data = {
                city: city,
                country: country
            };
            var dlg = Core.Utils.ModalForm.openModalForm("city.modal.html", "cityModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (result == true) {
                    this.$scope.components.events.cities.refresh();
                    this.$scope.editMode = false;
                }
            });
        }

        initCountriesGrid = () => {
            var ds = new kendo.data.DataSource({
                aggregate: [{ field: "CountryNu", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getCountries();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "CountryId",
                        fields: {
                            CountryNu: { type: "string" },
                            CountryNe: { type: "string" }

                        }
                    }
                }
            });

            var gridView: Components.IApxGrid = {
                columns: [
                    {
                        field: "CountryNu", titleTranslated: "CountryNu",
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.CountryNu ? data.CountryNu.count : 0) #'
                    },
                    { field: "CountryNe", titleTranslated: "CountryNe" }
                ],
                dataSource: ds,
                settings: { autoBind: false },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.model.selectedCountry = gridView.gridView.selectedItem();
                                this.$scope.components.citiesGrid.gridView.refresh();
                                if (this.$scope.model.selectedCountry) {
                                    this.$scope.editMoode = true;
                                }
                                else
                                    this.$scope.editMoode = false;

                            });
                        }
                    }
                ]
            };
            return gridView;
        }

        initCitiesGrid = () => {
            var ds = new kendo.data.DataSource({
                aggregate: [{ field: "CityNu", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var model = this.$scope.model;
                        if (model.selectedCountry !== undefined && model.selectedCountry !== null) {
                            var countryID = model.selectedCountry.CountryId;
                            var fn: any = this.dictionaryService.getCities(countryID);
                            fn.then(result => {
                                options.success(result.Result);

                                var gridData = this.$scope.components.citiesGrid.grid.dataSource.data();
                                if (gridData.length == 0)
                                    this.$scope.canDelete = true;
                                else
                                    this.$scope.canDelete = false;
                            });

                        } else {
                            options.success([]);
                        }
                    }
                },
                schema: {
                    model: {
                        fields: {
                            CityNu: { type: "string" },
                            CityNameEng: { type: "string" }
                        }
                    }
                }
            });

            var cityGrid: Components.IApxGrid = {
                columns: [
                    {
                        field: "CityNu", titleTranslated: 'CityNu',
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.CityNu ? data.CityNu.count : 0) #'
                    },
                    { field: "CityNameEng", titleTranslated: 'CityNe' }
                ],
                settings: { autoBind: false },
                dataSource: ds,
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selected = cityGrid.gridView.selectedItem();
                                if (selected) {
                                    this.$scope.editMode = true;
                                }
                                else
                                    this.$scope.editMode = false;
                            });
                        }
                    }
                ]
            };
            return cityGrid;
        }
    };


    export class CountryModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, CountryModal.$inject);
            $scope.setForm = (formScope) => {
                this.$timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data;
            $scope.closeForm = this.closeForm;
            this.$timeout(() => {
                if (!!$scope.model.CountryId) {
                    $scope.formName = "ქვეყნის რედაქტირება";
                } else {
                    $scope.formName = "ქვეყნის დამატება";
                }
            });
            $scope.save = this.save;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            if (!model.CountryNu) {
                this.toaster.pop('error', 'ყურადღება!', 'შეავსეთ აუცილებელი ველი!');
                return;
            }
            this.dictionaryService.saveCountry(model).then(result => {
                if (result.NewCountryId !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }

    export class CityModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, CityModal.$inject);
            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };

            $scope.model = data.city;
            if (data.city && data.city.CountryId)
                $scope.countryId = data.city.CountryId;
            else
                if (data.country)
                    $scope.countryId = data.country.CountryId;



            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.BrandId) {
                    $scope.formName = "ქალაქის რედაქტირება";
                } else {
                    $scope.formName = "ქალაქის დამატება";
                }
            });
            $scope.save = this.save;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        save = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            model.CountryId = this.$scope.countryId;
            if (!model.CityNu) {
                this.toaster.pop('error', 'ყურადღება!', 'შეავსეთ აუცილებელი ველი!');
                return;
            }
            this.dictionaryService.saveCity(model).then(result => {
                if (result.NewCityId !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}