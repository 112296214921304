/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {

    export interface PostsScope extends angular.IScope {
        model: {
            dataSource?: kendo.data.TreeListDataSource
        };
        Components: {
            PostsTreeList: Apex.Components.IApxTreeList,
            Data?: {
                selectedPost?: any,
                selectedID: number
            },
            selected: boolean;
            deleteMode?: boolean;
        };
        events: {
            refresh: Function,
            add: Function,
            addIn: Function,
            edit: Function,
            delete: Function,
            expand: Function,
            collapse: Function,
            setDefault: Function,
        };
        isCollapsed: boolean;
        Posttreelist: any;
        countInputOptions: Components.IApxNumber;
    }
    export class Posts extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', Globals.toaster, Globals.translate
        ];
        constructor(private $scope: PostsScope, private dictionaryService: Dictionary.Services.Dictionary, private toaster, private $translate) {
            super(arguments, Posts.$inject);
            $scope.isCollapsed = false;
            $scope.model = {
                dataSource: null
            },
                $scope.Components = {
                    Data: {
                        selectedPost: null,
                        selectedID: null
                    },
                    PostsTreeList: this.initPostsTreeList(),
                    selected: false,
                    deleteMode: false
                },
                $scope.events = {
                    refresh: this.refresh,
                    add: this.add,
                    addIn: this.addIn,
                    edit: this.edit,
                    delete: this.delete,
                    expand: this.expand,
                    collapse: this.collapse,
                    setDefault: this.setDefault,
                },
                $scope.countInputOptions = {
                    allowMinus: false,
                    min: 0.00,
                    decimals: 2,
                    numberType: Components.NumberType.Decimal
                };
            this.refresh();
        }
        add = () => {
            delete this.$scope.Components.Data.selectedPost;
            delete this.$scope.Components.Data.selectedID;
            this.openModal(this.$scope.Components.Data);
        };
        addIn = () => {
            delete this.$scope.Components.Data.selectedPost;
            if (!this.$scope.Components.Data.selectedID)
                return;
            else
                this.openModal(this.$scope.Components.Data);
        };
        edit = () => {
            delete this.$scope.Components.Data.selectedID;
            this.openModal(this.$scope.Components.Data);
        };
        delete = () => {
            if (!this.$scope.Components.Data || this.$scope.Components.deleteMode == true)
                return;
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deletePost(this.$scope.Components.Data.selectedPost.Codeid).then(r => {
                    if (r.success) {
                        this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successDeleted'));
                        this.$scope.events.refresh();
                    }
                });
            });
        };
        setDefault = () => {
            this.$scope.Components.Data.selectedPost.Mgrid = this.$scope.Components.Data.selectedPost.parentId;
            this.$scope.Components.Data.selectedPost.Defval = true;
            this.dictionaryService.savePost(this.$scope.Components.Data.selectedPost).then(r => {
                if (r)
                    this.refresh();
            });
        };
        refresh = () => {
            this.dictionaryService.getPost().then(result => {
                for (var index = 0; index < result.Result.length; index++) {
                    if (!result.Result[index].Mgrid) {
                        result.Result[index].Mgrid = null;
                    }
                }
                this.$scope.Components.PostsTreeList.treeList.setDataSource(this.convertToTreeListDataSource(result.Result));
            });
        }
        openModal = (data) => {
            var dlg = Core.Utils.ModalForm.openModalForm("post.modal.html", PostModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result == true) {
                    this.$scope.events.refresh();
                }
            });
        }
        expand = () => {
            this.$scope.Posttreelist = this.$scope.Components.PostsTreeList.treeList;
            var rowIndex = 0;
            var rows = this.$scope.Posttreelist.element.find("tr.k-treelist-group");
            if (rows && rows.length > 0) {
                for (var i = 0; i < rows.length; i++) {
                    this.$scope.Posttreelist.expand(rows[i]);
                }
            }

            this.$scope.isCollapsed = false;
        }
        collapse = () => {
            this.$scope.Posttreelist = this.$scope.Components.PostsTreeList.treeList;
            var rowIndex = 0;
            var row = this.$scope.Posttreelist.content.find("tr.k-treelist-group");
            for (var i = 0; i < row.length; i++) {
                this.$scope.Posttreelist.collapse(row[i]);
            }
            this.$scope.isCollapsed = true;
        }
        convertToTreeListDataSource = (data: any): kendo.data.TreeListDataSource => {
            return new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        expanded: true,
                        id: "Codeid",
                        fields: {
                            parentId: { field: "Mgrid", nullable: true }
                        },
                    }
                }
            });
        }
        initPostsTreeList = () => {
            var _treeList: Apex.Components.IApxTreeList = {
                options: {
                    columns: this.PostsTreeListColumns,
                    dataSource: {
                        data: [],
                        schema: {
                            model: {
                                expanded: true,
                                id: "Codeid",
                                parentId: "Mgrid",
                                fields: {
                                    Codeid: { type: "number", nullable: false },
                                    Mgrid: { nullable: true, type: "number" },
                                    Hcode: { nullable: true, type: "string" },
                                    Hrccrt: { nullable: true, type: "string" }
                                }
                            },
                        }
                    },
                    change: this.postTreeListSelectedChange,
                    editable: { mode: "inline"}, 
                    filterable: true,
                    resizable: true,
                    sortable: {
                        mode: "single"
                    },
                    selectable: true
                }
            }
            return _treeList;
        }
        postTreeListSelectedChange = (args: any) => {
            Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                this.$scope.Components.Data.selectedPost = (<JQuery>args.sender.select()).data().$scope.dataItem;
                this.$scope.Components.Data.selectedID = (<JQuery>args.sender.select()).data().$scope.dataItem.Codeid;
                if (this.$scope.Components.Data.selectedPost)
                    this.$scope.Components.selected = true;
                else
                    this.$scope.Components.selected = false;
                if (this.$scope.Components.Data.selectedPost.hasChildren)
                    this.$scope.Components.deleteMode = true;
                else
                    this.$scope.Components.deleteMode = false;
            });
        }
        PostsTreeListColumns: Apex.Core.Interfaces.ITreeListColumn[] = [
            { field: "Codeid", hidden: true, titleTranslated: "code", width: 50 },
            {
                field: "Hccrt", titleTranslated: "hccrt", width: 50,
                template: "<span ng-if='dataItem.Defval' class='def-color'> {{dataItem.Hccrt}}</span> <span ng-if='!dataItem.Defval'> {{dataItem.Hccrt}}</span>"
            },
            {
                field: "Hname", titleTranslated: "caption", width: 150,
                template: "<span ng-if='dataItem.Defval' class='def-color'> {{dataItem.Hname}}</span> <span ng-if='!dataItem.Defval'> {{dataItem.Hname}}</span>"
            },
            {
                field: "Wvg", titleTranslated: "salaryStat", width: 50,
                template: "<div ng-if='dataItem.Defval' class='def-color apx-grid-cell-number'> {{::dataItem.Wvg| number:2}}</div> <div ng-if='!dataItem.Defval'  class='apx-grid-cell-number'>{{::dataItem.Wvg| number:2}}</div>"
            },
            {
                field: "Wvu", titleTranslated: "salaryStatP", width: 50,
                template: "<div ng-if='dataItem.Defval' class='def-color apx-grid-cell-number'> {{::dataItem.Wvu| number:2}}</div> <div ng-if='!dataItem.Defval'  class='apx-grid-cell-number'>{{::dataItem.Wvu| number:2}}</div>"
            },
            {
                field: "Hcode", titleTranslated: "punct", width: 30,
                template: "<span ng-if='dataItem.Defval' class='def-color'> {{dataItem.Hcode}}</span> <span ng-if='!dataItem.Defval'> {{dataItem.Hcode}}</span>"
            }
        ];
    };




    export interface PostModalScope extends angular.IScope {
        model: {
            Data: any,
            codeID: number
        };
        Components: {
            citys: any[]
        };
        events: {
            save: Function,
            close: Function
        }
        AddMode: boolean;
        formName: string;
    }
    export class PostModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http
        ];
        constructor(private $scope: PostModalScope, data: any, private dictionaryService: Dictionary.Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService) {
            super(arguments, PostModal.$inject);
            $scope.AddMode = false;
            $scope.model = {
                Data: data.selectedPost,
                codeID: data.selectedID
            }
            $scope.events = {
                save: this.save,
                close: this.closeForm
            }
            $scope.Components = {
                citys: []
            }

            if (data.selectedPost) {
                $scope.AddMode = false;
                $scope.formName = "თანამდებობის რედაქტირება";
            } else {
                $scope.AddMode = true;
                $scope.formName = "თანამდებობის დამატება";
            }
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            if (!this.$scope.model.Data || !this.$scope.model.Data.Hname) {
                this.toaster.pop('error', 'ყურადღება', 'გთხოვთ შეავსოთ სავალდებულო ველი');
                return;
            }
            if (this.$scope.AddMode) {
                if (!this.$scope.model.codeID) {
                    this.dictionaryService.savePost(this.$scope.model.Data);
                }
                else {
                    this.$scope.model.Data.Mgrid = this.$scope.model.codeID;
                    this.dictionaryService.savePost(this.$scope.model.Data);
                }
            }
            else {
                this.$scope.model.Data.Mgrid = this.$scope.model.Data.parentId;
                this.dictionaryService.savePost(this.$scope.model.Data);
            }
            this.toaster.pop("success", "შეტყობინება", "შენახვა წარმატებით დასრულდა!");
            this.$uibModalInstance.close(true);
        }
    }
}