/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export interface IRecipesScope extends angular.IScope {
        components: {
            productsGrid: Components.IApxGrid;
            recipesGrid: Components.IApxGrid;
            categoriesLookup: Components.IApxLookup;
            producersLookup: Components.IApxLookup;
        };
        events: {
            expandCollapse: Function;
            refreshProds: Function,
            refreshProdRecipes: Function,
            editRecipe: Function;
        };
        model: {
            category?: any,
            producer?: any,
            active?: boolean
        };
        editBtnDisabled: boolean;
        isExpanded: boolean;
    }
    export class Recipes extends Core.Controller {

        public static $inject = [
            Globals.scope, 'dictionaryService'
        ];
        constructor(private $scope: IRecipesScope, private dictionaryService: Dictionary.Services.Dictionary) {
            super(arguments, Recipes.$inject);
            $scope.editBtnDisabled = true;
            $scope.isExpanded = false;
            $scope.model = {
                active: true
            };
            $scope.events = {
                expandCollapse: this.expandCollapse,
                refreshProds: this.refreshProds,
                refreshProdRecipes: this.refreshProdRecipes,
                editRecipe: this.editRecipe
            };
            $scope.components = {
                productsGrid: this.getProductsGrid(),
                recipesGrid: this.getRecipesGrid(),
                categoriesLookup: this.getCategoriesLookup(),
                producersLookup: this.getProducersLookup()
            };

            $scope.$watchCollection('[model.active,model.category,model.producer]', (active, category, producer) => {
                delete this.$scope.components.productsGrid.selectedObject;
            });

        }

        expandCollapse = () => {
            var selectedObject: Interfaces.IProductionGood = this.$scope.components.productsGrid.selectedObject;
            if (selectedObject != undefined && selectedObject != null) {
                this.$scope.isExpanded = !this.$scope.isExpanded;
                this.refreshProdRecipes();
            }
        };

        editRecipe = () => {
            var selectedObject: Interfaces.IProductionGood = this.$scope.components.productsGrid.selectedObject;
            var data = {
                product: selectedObject.Good
            };
            var modalSize = Core.Utils.ModalSize.Large;
            var dlg = Core.Utils.ModalForm.openModalForm("edit.recipe.html", "editRecipe" + 'Controller', data, modalSize, false);
            dlg.result.then(result => {
                if (typeof result != undefined && result !== false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        this.refreshProds();
                    });
                }
            });

        };

        refreshProds = () => {
            this.$scope.components.productsGrid.gridView.showLoading();
            this.dictionaryService.getProductRecipes(this.$scope.model.category == null ? null : this.$scope.model.category.CategoryId,
                this.$scope.model.producer == null ? null : this.$scope.model.producer.ProducerID, this.$scope.model.active ? false : null).then(response => {
                    this.$scope.components.productsGrid.dataSource.data(response.Result);
                    this.$scope.components.productsGrid.gridView.hideLoading();

                    if (!this.$scope.components.productsGrid.selectedObject)
                        this.$scope.editBtnDisabled = true;
                });
        };

        refreshProdRecipes = () => {
            var selectedObject: Interfaces.IProductionGood = this.$scope.components.productsGrid.selectedObject;
            if (selectedObject == null || selectedObject == undefined) {
                this.$scope.components.recipesGrid.dataSource.data([]);
                return;
            }
            if (selectedObject.HasReciept) {
                var accountingService: Account.Services.Account = Core.Utils.Helper.getServiceFromJs('accountService');
                this.$scope.components.recipesGrid.gridView.showLoading();
                accountingService.getProductRecipe(selectedObject.Good.ID, selectedObject.BCount, this.$scope.isExpanded).then(res => {
                    this.$scope.components.recipesGrid.dataSource.data(res.Result);
                    this.$scope.components.recipesGrid.gridView.hideLoading();
                });
            }
            else {
                this.$scope.components.recipesGrid.dataSource.data([]);
            }
        };

        getProductsGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                columns: [
                    {
                        field: 'Good.BCode',
                        titleTranslated: 'barcode', width: 70,
                        footerTemplate: '<span translate=empt> </span> #= kendo.toString(data.BCode ? data.BCode.count : 0) #',
                        template: '<span style="#= (Good.Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Good.BCode #</span>'
                    },
                    {
                        field: 'Good.InCode', titleTranslated: 'inCode', width: 80,
                        template: '<span style="#= (Good.Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Good.InCode #</span>'
                    },
                    {
                        field: 'Good.Name', titleTranslated: 'caption', width: 200,
                        template: '<span style="#= (Good.Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Good.Name #</span>'
                    },
                    {
                        field: 'Good.Unit', titleTranslated: 'unit', width: 40,
                        template: '<span style="#= (Good.Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Good.Unit #</span>'
                    },
                    {
                        field: 'Good.Producer.ProducerName', titleTranslated: 'producer', width: 100,
                        template: '<span style="#= (Good.Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Good.Producer.ProducerName #</span>'
                    },
                    {
                        field: 'HasReciept', titleTranslated: 'hasRecipe', filterable: 'boolean', width: 65, template: '<div class="text-center" ng-if="dataItem.HasReciept"><span class="fa fa-check" style="color:green"></span></div>'
                    },
                    {
                        field: 'BCount', width: 50, titleTranslated: 'Count', format: Core.Kendo.Format.Price2,
                        template: '<span ng-hide="#=BCount==0#" style="#= (Good.Spend==true) ? "color: \\#6b6c6d;" : "" #">#: BCount #</span>',
                        footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.BCount ? data.BCount.sum.toFixed(4) : 0) #'
                    }
                ],
                settings: { pageable: true },
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            id: 'Good.BCode',
                            fields: {
                                'HasReciept': { type: "boolean" },
                                'Good.BCode': { type: "string" },
                                'Good.InCode': { type: "string" },
                                'Good.Name': { type: "string" },
                                'Good.Unit': { type: "string" },
                                'Good.Producer.ProducerName': { type: "string" },
                                'BCount': { type: "number" }
                            }
                        }
                    },
                    pageSize: 100,
                    aggregate: [
                        { field: "BCode", aggregate: "count" },
                        { field: "BCount", aggregate: "sum" }
                    ]
                }),
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            var selectedObject: Interfaces.IProductionGood = this.$scope.components.productsGrid.selectedObject;
                            if (selectedObject == null || selectedObject == undefined) {
                                Core.Utils.Helper.safeApply(this.$scope, () => { this.$scope.editBtnDisabled = true; });
                                this.$scope.components.recipesGrid.dataSource.data([]);
                                return;
                            }
                            Core.Utils.Helper.safeApply(this.$scope, () => { this.$scope.editBtnDisabled = false; });

                            this.refreshProdRecipes();
                        }
                    },
                    {
                        eType: Components.EventType.DataBoud, eFunction: () => {
                            this.refreshProdRecipes();
                        }
                    }]
            };
            return grid;
        };
        getRecipesGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            fields: {
                                'CanExpand': { type: "boolean" },
                                'Materrial.BCode': { type: "string" },
                                'Materrial.Name': { type: "string" },
                                'Materrial.Category.CategoryName': { type: "string" },
                                'Materrial.Producer.ProducerName': { type: "string" },
                                'Materrial.InCode': { type: "string" },
                                'MCount': { type: "number" }
                            }
                        }
                    },
                    aggregate: [
                        { field: 'BCode', aggregate: 'count' },
                        { field: 'MCount', aggregate: 'sum' }
                    ]
                }),
                columns: [{
                    field: 'Materrial.BCode', titleTranslated: 'barcode', width: 70,
                    footerTemplate: '<span translate=empt> </span> #= kendo.toString(data.BCode ? data.BCode.count : 0) #'
                },
                {
                    field: 'Materrial.Name', titleTranslated: 'goods', width: 200
                },
                {
                    field: 'Materrial.Category.CategoryName', width: 130, titleTranslated: 'categoryName'
                },
                {
                    field: 'Materrial.Producer.ProducerName', width: 130, titleTranslated: 'producer'
                },
                {
                    field: 'Materrial.InCode', titleTranslated: 'inCode', width: 70
                },
                {
                    field: 'MCount', titleTranslated: 'Count', width: 70, format: Core.Kendo.Format.Price4,
                    footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.MCount ? data.MCount.sum.toFixed(4) : 0) #',
                },
                {
                    field: 'CanExpand', filterable: 'boolean', width: 90, titleTranslated: 'useAutomaticProduction',
                    template: '<div class="text-center" ng-if="dataItem.CanExpand"><span class="fa fa-check" style="color:green"></span></div>'
                }
                ],
            };

            return grid;
        };

        getCategoriesLookup = () => {
            var that = this;
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({
                    data: [],
                    transport: {
                        read: function (options) {
                            var fn = that.dictionaryService.getCategories();
                            fn.then(function (result) {
                                options.success(result.Result);
                            });
                        }
                    }
                }),
                columns: [{ field: 'CategoryName' }],
                valueText: 'CategoryName'
            }
            return lookUp;
        }

        getProducersLookup = () => {
            var that = this;
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({
                    data: [],
                    transport: {
                        read: function (options) {
                            var fn = that.dictionaryService.getManufactures();
                            fn.then(function (result) {
                                options.success(result.Result);
                            });
                        }
                    }
                }),
                columns: [{ field: 'ProducerName' }],
                valueText: 'ProducerName'
            }
            return lookUp;
        }

    }

    export interface IEditRecipeScope extends angular.IScope {
        product: Account.Interfaces.IGood;
        listForDelete: Array<Interfaces.IRecipeCost>;
        mainList: Array<Interfaces.IRecipeCost>;
        events: {
            delete: Function,
            save: Function,
            close: Function
        };
        formName: string;
        estimateCost: number;
        components: {
            recipesGrid: Components.IApxGrid;
            productLookup: Components.IApxLookup;
        };
        model: {
            bCount?: number
            modelForAdd: {
                product?: any;
                note?: string;
                count?: number;
                isAutomaticProduction?: boolean;
            }

        },
        recipClone: any;
        countMaskOptions: any;
        bCountInputOptions: any;
    }

    export class EditRecipe extends Core.Controller {

        public static $inject = [
            Globals.scope, 'dictionaryService', 'data', Globals.modalInstance, '$timeout', Globals.translate, Globals.toaster
        ];

        constructor(private $scope: IEditRecipeScope, private dictionaryService: Dictionary.Services.Dictionary, data: any, private $uibModalInstance, private $timeout: any, private $translate: any, private toaster: any) {
            super(arguments, EditRecipe.$inject);
            $scope.estimateCost = 0;
            $scope.model = { modelForAdd: {} };
            $scope.listForDelete = $scope.mainList = [];
            $scope.product = data.product;
            $scope.formName = $scope.product.BCode + ' - ' + $scope.product.Name;
            if ($scope.product.PackCount == 1) {
                $scope.bCountInputOptions = { numeric: true, numberType: Components.NumberType.Integer, min: 0, allowMinus: false };
            }
            else {
                $scope.bCountInputOptions = { numeric: true, decimals: 4, numberType: Components.NumberType.Decimal, min: 0, allowMinus: false };
            }
            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };

            $scope.$watch('model.modelForAdd.product', (product: any) => {
                if (product != undefined) {
                    if (product.PackCount != undefined) {
                        if (product.PackCount === 1) {
                            $scope.countMaskOptions = { decimals: 0, numberType: Components.NumberType.Integer, allowMinus: false };
                        } else {
                            $scope.countMaskOptions = { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false };
                        }
                    }
                }
            });

            dictionaryService.getRecipeCost($scope.product.ID).then(result => {
                if (result.Result != undefined && result.Result.length > 0) {
                    $scope.model.bCount = result.Result[0].BCount;
                    var r = 0;
                    for (var i = 0; i < result.Result.length; i++) {
                        var obj = result.Result[i];

                        obj.ProdPPId = $scope.product.ID;
                        obj.BCode = $scope.product.BCode;
                        obj.RecipeNote = (obj.RecipeNote != undefined && obj.RecipeNote != null) ? obj.RecipeNote.trim() : obj.RecipeNote;
                        if (obj.Cost != undefined)
                            r += result.Result[i].Cost;
                    }
                    $scope.estimateCost = r;
                    $scope.mainList = result.Result;
                    $scope.components.recipesGrid.dataSource.data(result.Result);
                    $timeout(() => {
                        var recGridData = this.$scope.components.recipesGrid.grid.dataSource.data();
                        if (recGridData) {
                            $scope.recipClone = jQuery.extend(true, {}, recGridData);
                        }
                    });

                }
                else {
                    $scope.model.bCount = 1;
                    $scope.components.recipesGrid.dataSource.data([]);
                    this.$scope.recipClone = [];
                }
            });

            $scope.events = {
                delete: this.delete,
                save: this.save,
                close: this.close
            };
            $scope.components = {
                recipesGrid: this.getRecipesGrid(),
                productLookup: this.getProductsLookup()
            };

            $timeout(() => {
                $('#multiDeleteRecipe').on('click', () => {
                    this.delete();
                });
            });
        }

        getProductsLookup = () => {
            var accountingService: Account.Services.Account = Core.Utils.Helper.getServiceFromJs('accountService');
            var productsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'BCode', width: 120, titleTranslated: 'barcode' },
                        { field: 'ProductsNu', titleTranslated: 'goods' }
                    ],
                    dataSource: Core.Kendo.GridView.initAutoQueryDataSource(accountingService.productsQueryUrl),
                    valueText: 'ProductsNu'
                }

            return productsLookup;
        }

        delete = () => {
            var selectedObject: Interfaces.IRecipeCost = this.$scope.components.recipesGrid.selectedObject;
            if (this.$scope.components.recipesGrid.selectedObject != undefined) {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
                r.result.then(() => {
                    for (var i = 0; i < this.$scope.mainList.length; i++) {
                        if (this.$scope.mainList[i].RecipeId == selectedObject.RecipeId) {
                            selectedObject.MProdPPId = "-1";
                            this.$scope.listForDelete.push(selectedObject);
                            this.$scope.mainList.splice(i, 1);
                            break;
                        }
                    }
                    this.$scope.components.recipesGrid.dataSource.data(this.$scope.mainList);

                });
            }
        };

        save = () => {
            var ds: any = this.$scope.components.recipesGrid.grid.dataSource.data();
            var requestList: any = [];


            if (this.$scope.model.bCount == 0) {
                this.toaster.pop('error', this.$translate.instant('Attention'), 'გამოსაშვები რაოდენობა უნდა იყოს 0-ზე მეტი.');
                return;
            }

            for (var i = 0; i < ds.length; i++) {
                ds[i].BCount = this.$scope.model.bCount;
                requestList.push(ds[i]);
            }

            for (var i = 0; i < this.$scope.listForDelete.length; i++) {
                requestList.push(this.$scope.listForDelete[i]);
            }

            if (requestList.length == 0) {
                this.toaster.pop('error', this.$translate.instant('Attention'), 'არ გაქვთ შესანახი მონაცემები');
                return;
            }

            this.dictionaryService.saveRecipeDetails(<Interfaces.IRecipeCost[]>requestList).then
                (
                res => {
                    if (res.success != false) {
                        this.$uibModalInstance.close(true);
                    }
                }
                );
        };

        close = () => {
            if (this.$scope.recipClone.length != this.$scope.components.recipesGrid.dataSource.data().length) {
                var r = Core.Utils.ModalForm.openConfirmation("გსურთ ცვლილებების გაუქმება?");
                r.result.then(res => {
                    if (res)
                        this.$uibModalInstance.close();
                    else
                        return;
                    // $(document).unbind('keydown', this.closeHandle);
                });
            } else {
                this.$uibModalInstance.close();
                $(document).unbind('keydown', this.closeHandle);
            }
        };
        closeHandle = (e) => {
            if (e.keyCode === 27) {
                this.close();
            }
        }
        getCancelEvent(): Components.IEvent {
            var cancelEvent: Components.IEvent =
                {
                    eType: Components.EventType.Cancel,
                    eFunction: () => {
                        var ds = this.$scope.components.recipesGrid.grid.dataSource.data();
                        ds.remove(ds[0]);

                        this.$scope.components.recipesGrid.grid.dataSource.sync();

                        this.$scope.model.modelForAdd = {

                        };
                    }
                }
            return cancelEvent;
        }

        getSaveEvent(): Components.IEvent {
            var saveEventType: Components.EventType = Components.EventType.Save;
            var saveEvent: Components.IEvent =
                {
                    eType: saveEventType,
                    eFunction: (e: any) => {

                        if (e.model == undefined) {

                            if (this.$scope.model.modelForAdd.product == undefined || this.$scope.model.modelForAdd.product == null) {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('ChooseProduct'));
                                });
                                e.preventDefault();
                                return;
                            }

                            if (this.$scope.model.modelForAdd.count == undefined || this.$scope.model.modelForAdd.count == 0) {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    this.toaster.pop('error', this.$translate.instant('Attention'), 'შეიყვანეთ ნულისაგან განსხვავებული რაოდენობა.');

                                });
                                e.preventDefault();
                                return;
                            }

                            var ds = this.$scope.components.recipesGrid.grid.dataSource.data();

                            if (_.where(ds, { 'MProdPPId': this.$scope.model.modelForAdd.product.ProdPPId }).length > 0) {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    this.toaster.pop('error', this.$translate.instant('Attention'), 'არჩეული ნედლეული უკვე დამატებულია.');

                                });
                                e.preventDefault();
                                return;
                            }

                            var obj: Interfaces.IRecipeCost = {
                                ProdPPId: this.$scope.product.ID,
                                BCode: this.$scope.product.BCode,
                                BCount: this.$scope.model.bCount,
                                CanExpand: this.$scope.model.modelForAdd.isAutomaticProduction,
                                MCount: Number(this.$scope.model.modelForAdd.count),
                                RecipeNote: this.$scope.model.modelForAdd.note,
                                MProdPPId: this.$scope.model.modelForAdd.product.ProdPPId,
                                InCode: this.$scope.model.modelForAdd.product.InCode,
                                Material: this.$scope.model.modelForAdd.product.ProductsNu,
                                MBcode: this.$scope.model.modelForAdd.product.BCode,
                                PackCount: this.$scope.model.modelForAdd.product.PackCount,
                                Producer: this.$scope.model.modelForAdd.product.ProducerNu,
                                Unit: this.$scope.model.modelForAdd.product.Unit,
                            };
                            ds.remove(ds[0]);
                            ds.unshift(obj);
                            this.$scope.components.recipesGrid.grid.dataSource.sync();
                            this.$scope.components.recipesGrid.grid.refresh();


                            this.$timeout(() => {
                                this.$scope.model.modelForAdd = { count: 0 };
                            }, 100);
                        }
                        else {
                            this.$scope.components.recipesGrid.grid.refresh();
                            // edit in grid
                        }
                    }
                };
            return saveEvent;
        }

        getEditEvent(): Components.IEvent {
            var editEventType: Components.EventType = Components.EventType.Edit;
            var editEvent: Components.IEvent =
                {
                    eType: editEventType,
                    eFunction: this.editEventFunctionality
                };
            return editEvent;
        }

        editEventFunctionality = (e: any) => {
            if (e.model.PackCount === 1) {
                var options: any = { numeric: true, min: 0, allowMinus: false }
                var count = $(".apx-col-edit-MCount").find("input").inputmask('integer', options);
            }
        }

        getRecipesGrid = (): Components.IApxGrid => {
            var that = this;
            var grid: Components.IApxGrid = {
                dataSource: new kendo.data.DataSource({
                    data: [],
                    aggregate: [
                        { field: 'MBcode', aggregate: 'count' },
                        { field: 'Cost', aggregate: 'sum' },
                        { field: 'UnitCost', aggregate: 'sum' },
                        { field: 'MCount', aggregate: 'sum' },
                    ],
                    schema: {
                        model: {
                            fields: {
                                CanExpand: { type: 'boolean' },
                                MBcode: { editable: false, type: 'string' },
                                Material: { editable: false, type: 'string' },
                                Producer: { editable: false, type: 'string' },
                                Unit: { editable: false, type: 'string' },
                                UnitCost: { editable: false, type: 'number' },
                                Cost: { editable: false, type: 'number' },
                                MCount: { type: 'number' },
                                RecipeNote: { type: 'string' }
                            }
                        }
                    }
                }),
                gridEvents: [
                    this.getEditEvent(),
                    this.getSaveEvent(),
                    this.getCancelEvent()
                ],
                columns: this.productGridColumns,
                templates: this.productGridColumnTemplates,
                parentScope: this.$scope,
                settings: {
                    editble: {
                        popupTemplateID: 'add_recipe_popup_editor',
                        popupWidth: 750
                    },
                    height: 300,

                    gridButtons: {
                        multiDelete: { enabled: true, id: 'Recipe' },
                        add: { enabled: true, id: 'Recipe' }
                    }
                }
            };
            return grid;
        };

        productGridColumnTemplates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'CanExpand',
                editor(container, options) {
                    $('<input type="checkbox" style="width:90%" ng-model="dataItem.' + options.field + '"/>')
                        .appendTo(container);
                }
            },
            //{
            //    editformat: "{0:##.####}",
            //    fieldName: 'MCount', editor: function (container, options) {

            //        $("<input  class='apx-color-lemonchiffon' name='" + options.field + "' id=countInput />")
            //            .appendTo(container)
            //            .kendoNumericTextBox({
            //                decimals: 4,
            //                spinners: false,
            //                min: 0,
            //                step: 0
            //            });
            //    }
            //}

        ];

        productGridColumns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'MBcode', width: 100, titleTranslated: 'barcode',
                footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.MBcode ? data.MBcode.count : 0) #'
            },
            {
                field: 'Material', titleTranslated: 'caption', width: 250
            },
            {
                field: 'Producer', width: 200, titleTranslated: 'producer'
            },
            {
                field: 'Unit', width: 60, titleTranslated: 'ZE'
            },
            {
                field: 'UnitCost', width: 80, format: Core.Kendo.Format.Price4, titleTranslated: 'itemCost1',
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.UnitCost && data.UnitCost.sum ? parseFloat(data.UnitCost.sum).toFixed(4) : 0) #'
            },
            {
                field: 'Cost', width: 80, format: Core.Kendo.Format.Price4, titleTranslated: 'cost',
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Cost && data.Cost.sum ? parseFloat(data.Cost.sum).toFixed(4) : 0) #'
            },
            {
                field: 'MCount', width: 80, titleTranslated: 'Count', format: Core.Kendo.Format.Price4, editable: true,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.MCount && data.MCount.sum ? parseFloat(data.MCount.sum).toFixed(4) : 0) #'
            },
            {
                field: 'RecipeNote', width: 230, titleTranslated: 'note', editable: true
            },
            {
                field: 'CanExpand', filterable: 'boolean', width: 90, titleTranslated: 'useAutomaticProduction', template: '<div class="text-center" ng-if="dataItem.CanExpand"><span class="fa fa-check" style="color:green"></span></div>'
            }
        ];

    }
}