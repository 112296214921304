/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Brands extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $timeout) {
            super(arguments, Brands.$inject);
            $scope.model = {
                selectedBrand: {}
            }
            $scope.editMode = false;
            $scope.components = {
                brandsGrid: this.initBrandGrid(),
                events: {
                    add: this.add,
                    delete: this.delete,
                    edit: this.edit,
                    refresh: () => {
                        $scope.components.showRefreshInfo = false;
                        $scope.components.brandsGrid.gridView.showLoading();
                        $timeout(() => {
                            $scope.components.brandsGrid.gridView.refresh();
                        });
                    }
                },
                showRefreshInfo: true
            }
        }

        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("brand.modal.html", "brandModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.refresh();
                }
            });
        }
        edit = () => {
            this.$scope.model.selectedBrand = this.$scope.components.brandsGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }
        add = () => {
            this.openModal({ selectedBrand: {} });
        }
        delete = () => {
            this.$scope.model.selectedBrand = this.$scope.components.brandsGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            var selectedID = this.$scope.model.selectedBrand.BrandId;
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteBrand(selectedID).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });
        }


        public static brandsDataSource = (pageSize: number = null) => {
            var dictionaryService = Core.Utils.Helper.getServiceFromJs("dictionaryService");
            var dataSource = new kendo.data.DataSource({
                aggregate: [{ field: "Abbreviation", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = dictionaryService.getBrands();
                        fn.then(result => {

                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "BrandId",
                        fields: {
                            Abbreviation: { type: "string" },
                            Name: { type: "string" },
                            BrandNameE: { type: "string" }
                        }
                    }
                }
            });
            if (pageSize != null) {
                dataSource.pageSize(pageSize);
            }
            return dataSource;
        }

        initBrandGrid = () => {
            var grid: Components.IApxGrid = {
                dataSource: Brands.brandsDataSource(),
                columns: [
                    {
                        field: 'Abbreviation', titleTranslated: 'abbreviation', width: 200,
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.Abbreviation ? data.Abbreviation.count : 0) #'
                    },
                    { field: 'Name', titleTranslated: 'nameCaption' },
                    { field: 'BrandNameE', titleTranslated: 'nameCaptionEn' }
                ],
                settings: { autoBind: false },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selected = grid.gridView.selectedItem();
                                if (selected)
                                    this.$scope.editMode = true;
                                else
                                    this.$scope.editMode = false;
                            });
                        }
                    }
                ]
            };
            return grid;
        }
    };

    export class BrandModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, BrandModal.$inject);

            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data.selectedBrand;
            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.BrandId) {
                    $scope.formName = "ბრენდის რედაქტირება";
                } else {
                    $scope.formName = "ბრენდის დამატება";
                }
            });
            $scope.save = this.save;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {

            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველი!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            model.Abbreviation = model.Abbreviation || " ";
            model.BrandNameE = model.BrandNameE || " ";

            this.dictionaryService.saveBrand(model).then(result => {
                if (result !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}