/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Types extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', Globals.translate, '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $translate, private $timeout) {
            super(arguments, Types.$inject);
            $scope.model = {
                selectedType: {}
            }
            $scope.editMoode = false;
            $scope.components = {
                typesGrid: this.initTypesGrid(),
                events: {
                    add: this.add,
                    delete: this.delete,
                    edit: this.edit,
                    refresh: () => {
                        $scope.components.showRefreshInfo = false;
                        $scope.components.typesGrid.gridView.showLoading();
                        $timeout(() => {
                            $scope.components.typesGrid.gridView.refresh();
                        });
                    }
                },
                showRefreshInfo: true
            }
        }


        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("type.modal.html", "typeModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.refresh();
                }
            });
        }
        edit = () => {
            this.$scope.model.selectedType = this.$scope.components.typesGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }
        add = () => {
            this.$scope.model.selectedType = {};
            this.openModal(this.$scope.model);
        }
        delete = () => {
            this.$scope.model.selectedType = this.$scope.components.typesGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteType(this.$scope.model.selectedType.ID).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });
        }


        public static prodTypeDataSource = (pageSize: number = null) => {
            var dictionaryService = Core.Utils.Helper.getServiceFromJs("dictionaryService");
            var dataSource = new kendo.data.DataSource({
                aggregate: [{ field: "LDefault", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = dictionaryService.getTypes();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "ID",
                        fields: {
                            LDefault: { type: "boolean" },
                            Name: { type: "string" },
                            NameEn: { type: "string" },
                            Spend: { type: "boolean" }
                        }
                    }
                }
            });
            if (pageSize != null) {
                dataSource.pageSize(pageSize);
            }
            return dataSource;
        }



        initTypesGrid = () => {

            var grid: Components.IApxGrid = {
                dataSource: Types.prodTypeDataSource(),
                columns: [
                    {
                        field: "LDefault", titleTranslated: "LDefault", width: 100,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: this.$translate.instant('Default') },
                                    { value: 0, text: this.$translate.instant('none') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.LDefault' class='text-center text-warning'><i class='fa fa-star'></i></div> <span ng-if='!dataItem.LDefault'></span>",
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.LDefault ? data.LDefault.count : 0) #'
                    },
                    { field: "Name", titleTranslated: "nameCaption" },
                    { field: "NameEn", titleTranslated: "nameCaptionEn" },
                    {
                        field: "Spend", titleTranslated: "Spend", width: 100,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: this.$translate.instant('isSelected') },
                                    { value: 0, text: this.$translate.instant('isNotSelected') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.Spend' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Spend'></span>"
                    },
                ],
                settings: {
                    autoBind: false
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var seleted = grid.gridView.selectedItem();
                                if (seleted) {
                                    this.$scope.editMoode = true;
                                }
                                else
                                    this.$scope.editMoode = false;
                            });
                        }
                    }
                ]
            };
            return grid;
        }
    };

    export class TypeModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, TypeModal.$inject);


            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data.selectedType;
            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.ID) {
                    $scope.formName = "ტიპის რედაქტირება";
                } else {
                    $scope.formName = "ტიპის დამატება";
                }
            });
            $scope.save = this.save;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველი!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            this.dictionaryService.saveType(model).then(result => {
                if (result.NewPPCatId !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}