/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {

    export interface AnyScope extends angular.IScope {
        components: {
            anyGrid: Components.IApxGrid,
            anyDetailsGrid: Components.IApxGrid,
            events: { anyDetails: any }
        };
        selectedAny?: any;
        selectedAnyDetails?: any;
        rowVisibility?: {
            VmVisible?: boolean,
            VpVisible?: boolean,
            ViVisible?: boolean,
            VdVisible?: boolean,
            VmCaption?: string,
            VpCaption?: string,
            ViCaption?: string,
            VdCaption?: string
        }
    }

    export class Any extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', Globals.translate, Globals.toaster
        ];
        constructor(private $scope: AnyScope, private dictionaryService: Dictionary.Services.Dictionary) {
            super(arguments, Any.$inject);
            $scope.components = {
                anyGrid: this.initAnyGrid(),
                anyDetailsGrid: this.initAnyDetailsGrid(),
                events: {
                    anyDetails: {
                        add: this.addAnyDetails,
                        edit: this.editAnyDetails,
                        delete: this.deleteAnyDetails
                    }
                }
            }
            $scope.rowVisibility = {
                VmVisible: false,
                VmCaption: "",
                VpVisible: false,
                VpCaption: "",
                ViVisible: false,
                ViCaption: "",
                VdVisible: false,
                VdCaption: ""
            }

            this.onCompanyChange = (newCompany, oldCompany) => {
                $scope.components.anyDetailsGrid.grid.dataSource.data([]);
                $scope.components.anyGrid.gridView.refresh();
            }

        }

        addAnyDetails = () => {
            this.$scope.selectedAnyDetails = {};
            this.openAnyDetailsModal(this.$scope.selectedAnyDetails, this.$scope.selectedAny, this.$scope.rowVisibility);
            delete this.$scope.selectedAnyDetails;
        }

        editAnyDetails = () => {
            this.$scope.components.anyDetailsGrid.dataSource.read();
            this.$scope.selectedAnyDetails = this.$scope.components.anyDetailsGrid.gridView.selectedItem();
            this.openAnyDetailsModal(this.$scope.selectedAnyDetails, this.$scope.selectedAny, this.$scope.rowVisibility);
        }

        deleteAnyDetails = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteAnyDetails(this.$scope.selectedAnyDetails.codeid).then((result: any) => {
                    if (!!result.statusCode) {
                        toaster.pop('error', "Error", translate.instant('dontDelete'));
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.anyDetailsGrid.dataSource.read();
                        delete this.$scope.selectedAnyDetails;
                    }
                });
            });

        }

        openAnyDetailsModal = (detailsData, mgridData, RowsVisibility) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var data: any = { selectedData: detailsData, selectedMgrIdData: mgridData, rowVisibility: RowsVisibility }
            var dlg = Core.Utils.ModalForm.openModalForm("anyDetails.modal.html", "anyDetailsModalController", data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.anyDetailsGrid.dataSource.read();
                    this.$scope.components.anyDetailsGrid.dataSource.data(result.Result);
                    //_.forEach(result.Result, (item: IPosUserSelectable) => { item.isSelected = false; });
                    //this.$scope.components.anyDetailsGrid.dataSource.data(result.Result);  
                }
            });
        }

        private conditionalRows(): any {
            this.$scope.rowVisibility.VmVisible = this.$scope.rowVisibility.VpVisible = this.$scope.rowVisibility.ViVisible = this.$scope.rowVisibility.VdVisible = false;
            this.$scope.rowVisibility.VmCaption = this.$scope.rowVisibility.VpCaption = this.$scope.rowVisibility.ViCaption = this.$scope.rowVisibility.VdCaption = ""

            var VCaptions: string = this.$scope.selectedAny.VCaption !== null && this.$scope.selectedAny.VCaption !== undefined ? this.$scope.selectedAny.VCaption : "";
            var vtype: string = this.$scope.selectedAny.VType !== null && this.$scope.selectedAny.VType !== undefined ? this.$scope.selectedAny.VType : "";

            var colCaptions: string[] = VCaptions.split(',');
            var captionslist = colCaptions;
            var res = [];
            var data = vtype.split('');

            for (var i = 0; i < data.length; i++) {
                if (data[i] === "1") {
                    res.push(i);
                }
            };

            var colv: number = 0
            for (var i = 0; i < res.length; i++) {
                switch (res[i]) {
                    case 0:
                        {
                            this.$scope.rowVisibility.VmVisible = true;
                            this.$scope.rowVisibility.VmCaption = captionslist.length > colv ? captionslist[colv] : "";
                            colv++;
                            break;
                        }
                    case 1:
                        {
                            this.$scope.rowVisibility.VpVisible = true;
                            this.$scope.rowVisibility.VpCaption = captionslist.length > colv ? captionslist[colv] : "";
                            colv++;
                            break;
                        }
                    case 2:
                        {
                            this.$scope.rowVisibility.ViVisible = true;
                            this.$scope.rowVisibility.ViCaption = captionslist.length > colv ? captionslist[colv] : "";
                            colv++;
                            break;
                        }
                    case 3:
                        {
                            this.$scope.rowVisibility.VdVisible = true;
                            this.$scope.rowVisibility.VdCaption = captionslist.length > colv ? captionslist[colv] : "";
                            colv++;
                            break;
                        }
                }
            }
        }

        initAnyGrid = () => {
            var anyGrid: Components.IApxGrid = {
                columns: [
                    { field: 'HCode', titleTranslated: 'code', width: 80, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.HCode ? data.HCode.count : 0) #' },
                    { field: 'Name', titleTranslated: 'nameCaption' },
                    { field: 'NameEn', titleTranslated: 'nameCaptionEn' }
                ],
                dataSource: new kendo.data.DataSource({
                    schema: {
                        model: {
                            fields: {
                                HCode: { type: "number" },
                                Name: { type: "string" },
                                NameEn: { type: "string" }
                            }
                        }
                    },
                    transport: {
                        read: (options) => {
                            var fn: any = this.dictionaryService.getFixVal(this.dictionaryService.mgrIdAny);
                            fn.then(result => {
                                options.success(result.Result || []);
                            });
                        }
                    },
                    aggregate:
                    [
                        { field: "HCode", aggregate: "count" }
                    ]

                }),

                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedAny = anyGrid.gridView.selectedItem();
                                delete this.$scope.selectedAnyDetails;
                                if (this.$scope.selectedAny != null) {
                                    this.$scope.components.anyDetailsGrid.gridView.showLoading();
                                    this.$scope.components.anyDetailsGrid.gridView.refresh();
                                    this.conditionalRows();
                                    var compile = Apex.Core.Utils.Helper.getServiceFromJs("$compile");

                                    if (this.$scope.rowVisibility.VmVisible == true) {
                                        this.$scope.components.anyDetailsGrid.grid.showColumn('vmString()');
                                        var result = compile($('<div uib-tooltip="' + this.$scope.rowVisibility.VmCaption + '" style= "text-overflow:ellipsis; display: inline;" tooltip-append-to-body="true" tooltip-animation="true" class="ng-scope">' + this.$scope.rowVisibility.VmCaption + '</div>'))(this.$scope);
                                        this.$scope.components.anyDetailsGrid.grid.thead.find("[data-field=vm] a").html(result);
                                    }
                                    else {
                                        this.$scope.components.anyDetailsGrid.grid.hideColumn('vmString()');
                                    }
                                    if (this.$scope.rowVisibility.VpVisible == true) {
                                        this.$scope.components.anyDetailsGrid.grid.showColumn('vpString()');
                                        var result = compile($('<div uib-tooltip="' + this.$scope.rowVisibility.VpCaption + '" style= "text-overflow:ellipsis; display: inline;" tooltip-append-to-body="true" tooltip-animation="true" class="ng-scope">' + this.$scope.rowVisibility.VpCaption + '</div>'))(this.$scope);
                                        this.$scope.components.anyDetailsGrid.grid.thead.find("[data-field=vp] a").html(result);
                                    }
                                    else {
                                        this.$scope.components.anyDetailsGrid.grid.hideColumn('vpString()');
                                    }
                                    if (this.$scope.rowVisibility.ViVisible == true) {
                                        this.$scope.components.anyDetailsGrid.grid.showColumn('vi');
                                        var result = compile($('<div uib-tooltip="' + this.$scope.rowVisibility.ViCaption + '" style= "text-overflow:ellipsis; display: inline;" tooltip-append-to-body="true" tooltip-animation="true" class="ng-scope">' + this.$scope.rowVisibility.ViCaption + '</div>'))(this.$scope);
                                        this.$scope.components.anyDetailsGrid.grid.thead.find("[data-field=vi] a").html(result);
                                    }
                                    else {
                                        this.$scope.components.anyDetailsGrid.grid.hideColumn('vi');
                                    }
                                    if (this.$scope.rowVisibility.VdVisible == true) {
                                        this.$scope.components.anyDetailsGrid.grid.showColumn('vd');
                                        var result = compile($('<div uib-tooltip="' + this.$scope.rowVisibility.VdCaption + '" style= "text-overflow:ellipsis; display: inline;" tooltip-append-to-body="true" tooltip-animation="true" class="ng-scope">' + this.$scope.rowVisibility.VdCaption + '</div>'))(this.$scope);
                                        this.$scope.components.anyDetailsGrid.grid.thead.find("[data-field=vd] a").html(result);
                                    }
                                    else {
                                        this.$scope.components.anyDetailsGrid.grid.hideColumn('vd');
                                    }
                                }
                                else {
                                    this.$scope.components.anyDetailsGrid.dataSource.data([]);
                                }

                            });
                        }
                    },
                    {
                        eType: Components.EventType.DataBoud, eFunction: (e) => {
                            if (this.$scope.selectedAny === null || this.$scope.selectedAny === undefined) {
                                e.sender.select(e.sender.tbody.find('tr:first'));
                                e.sender.select(e.sender.tbody.find("tr[role='row']:first"));
                            }
                        }
                    }
                ]
            };
            return anyGrid;
        }

        private templates: Array<Core.Interfaces.IGridColumnTemplate> = [

            { fieldName: 'canceled', template: '<div class="text-center" ng-if="dataItem.canceled"><span class="fa fa-check"></span></div>' },
            { fieldName: 'vd', template: '<span>{{::dataItem.vd | dateFormat | date:"dd/MM/yyyy"}}</span>' },
            { fieldName: 'vm', template: '<div class="apx-grid-cell-number">{{::dataItem.vm| number:4}}</div>' },
            { fieldName: 'vi', template: '<div class="apx-grid-cell-number">{{::dataItem.vi| number:0}}</div>' }
        ];
        initAnyDetailsGrid = () => {
            var anyDetailsGrid: Components.IApxGrid = {
                columns: [
                    { field: 'hcode', titleTranslated: 'code', width: 80, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.codeid ? data.codeid.count : 0) #' },
                    { field: 'hname', titleTranslated: 'nameCaption' },
                    { field: 'hnamee', titleTranslated: 'nameCaptionEn' },
                    { field: 'vm', hidden: true, width: 60 },
                    { field: 'vp', hidden: true, width: 60, format: Core.Kendo.Format.Percent },
                    { field: 'vi', hidden: true, width: 60, filterable: false },
                    { field: 'vd', hidden: true, width: 80, filterable: false },
                    {
                        field: 'canceled', titleTranslated: 'Canceled', width: 90, objectType: 'boolean', 
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: " " },
                                    { value: true, text: "შეჩერებული" },
                                    { value: false, text: "აქტიური" }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"

                            })
                        },
                    }
                ],
                templates: this.templates,
                dataSource: this.initAnyDetailsDS(),
                settings: { autoBind: false },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedAnyDetails = anyDetailsGrid.gridView.selectedItem();
                            });
                        }
                    },
                    {
                        eType: Components.EventType.DataBoud, eFunction: (e) => {
                            if (this.$scope.selectedAnyDetails != undefined && this.$scope.selectedAnyDetails != null) {
                                var pageData = this.$scope.components.anyDetailsGrid.grid.dataSource.data();
                                for (var i = 0; i < pageData.length; i++) {
                                    if (pageData[i].codeid == this.$scope.selectedAnyDetails.codeid) {
                                        $("[data-uid='" + pageData[i].uid + "']").addClass('k-state-selected');
                                        break;
                                    }
                                }
                            }
                            if (this.$scope.selectedAnyDetails === null || this.$scope.selectedAnyDetails === undefined) {
                                e.sender.select(e.sender.tbody.find('tr:first'));
                                e.sender.select(e.sender.tbody.find("tr[role='row']:first"));
                            }
                        }
                    }]
            };
            return anyDetailsGrid;
        }
        initAnyDetailsDS = () => {
            var dataSource = new kendo.data.DataSource({
                schema: {
                    model: {

                        field: {
                            hcode: { type: "number" },
                            hname: { type: "string" },
                            hnamee: { type: "string" },
                            vm: { type: "string" },
                            vp: { type: "string" },
                            vi: { type: "string" },
                            vd: { type: "string" },
                            canceled: { type: "boolean" }
                        },

                        HCodeString: function (item) {
                            return this.hcode.toString();
                        },
                        vmString: function (item) {
                            return this.vm.toString();
                        },
                        vpString: function (item) {
                            return this.vp.toString();
                        }
                        //,
                        //viString: function (item) {
                        //    return this.vi.toString();
                        //},
                        //vdString: function (item) {
                        //    return this.vd.toString();
                        //}

                    }
                },
                transport: {
                    read: (options) => {
                        var model = this.$scope;
                        var mgrId: string = model.selectedAny !== null ? model.selectedAny.CodeId : undefined;
                        var fn = this.dictionaryService.getFixValDtAny(mgrId);
                        fn.then(result => { 
                            options.success(result.Result || []);
                        });
                    }
                },
                aggregate:
                [
                    { field: "codeid", aggregate: "count" }
                ]
            });
            return dataSource;
        }
    }

    export interface AnyDetailsScope extends angular.IScope, AnyScope {
        anys: any;
        MgrId: string;
        selectedAny: any;
        model: { hcode: string, hname: string, hnamee: string, mgrId?: string, vm?: any, vp?: any, vi?: any, vd?: Date }
        closeForm: Function,
        formName: string,
        save: Function
    }

    export class AnyDetailsModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http,'$timeout'
        ];
        constructor(private $scope: AnyDetailsScope, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, AnyDetailsModal.$inject);
            $scope.rowVisibility = {
                VmVisible: false,
                VmCaption: "",
                VpVisible: false,
                VpCaption: "",
                ViVisible: false,
                ViCaption: "",
                VdVisible: false,
                VdCaption: ""
            }
            $scope.anys = data.selectedData;
            $scope.MgrId = data.selectedMgrIdData.CodeId;
            $scope.selectedAny = data.selectedMgrIdData;

            if (_.values($scope.anys).length != 0) {
                $scope.model = $scope.anys;
                $scope.model.vd = this.todate($scope.anys.vd);
            }
            else {
                $scope.model = { hcode: '', hname: '', hnamee: '', vm: 0, vp: 0, vi: 0, vd: new Date() };
            }
            this.$scope.rowVisibility.VmVisible = data.rowVisibility.VmVisible;
            this.$scope.rowVisibility.VpVisible = data.rowVisibility.VpVisible;
            this.$scope.rowVisibility.ViVisible = data.rowVisibility.ViVisible;
            this.$scope.rowVisibility.VdVisible = data.rowVisibility.VdVisible;
            this.$scope.rowVisibility.VmCaption = data.rowVisibility.VmCaption;
            this.$scope.rowVisibility.VpCaption = data.rowVisibility.VpCaption;
            this.$scope.rowVisibility.ViCaption = data.rowVisibility.ViCaption;
            this.$scope.rowVisibility.VdCaption = data.rowVisibility.VdCaption;
            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.anys.codeid) {
                    $scope.formName = "რედაქტირება";
                } else {
                    $scope.formName = "დამატება";
                }
            });

            $scope.save = this.save;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        todate(d) {
            if (!!d) {
                if (d.getMonth)
                    return d;
                var myDate = new Date(parseInt(d.replace('/Date(', '')));
                return myDate;
            }
            return null;
        }

        save = () => {
            this.$scope.model.mgrId = this.$scope.MgrId;
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            if (this.$scope.model.hcode == '' || this.$scope.model.hname == '') {
                this.toaster.pop('error', 'გაფრთხილება!', 'შეიყვანეთ მონაცემები სრულად');
                return;
            }
            else {
                this.dictionaryService.saveAnyDetails(this.$scope.model).then(result => {
                    if (result.NewCodeId > 0) {
                        this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$uibModalInstance.close(true);
                    }
                    return " ";
                });
            }
        }
    }
}