/// <reference path='./_reference.ts' />
module Apex.Dictionary {
    'use strict';

    var apxDictionary = new Core.Module('dictionary', ['apex.webUI.uiFrame', 'apex.webUI.components', 'apex.webUI.account'], Dictionary);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'dictionary',
                url: '^/dictionary',
                template: '<ui-view/>',
                navigation: { caption: 'dictionaries', navigationClass: 'md md-school' },
                childRoutes: [
                    {
                        state: 'products',
                        template: '<ui-view/>',
                        url: '/products',
                        navigation: {
                            caption: 'goods1'
                        },
                        childRoutes: [
                            {
                                state: 'Prodpp',
                                url: '/prodpp',
                                controller: Dictionary.Controllers.Prodpp.getName(),
                                templateUrl: "prodpp.html",
                                navigation: {
                                    caption: 'goods'
                                }
                            },
                            {
                                state: 'units',
                                url: '/units',
                                controller: Dictionary.Controllers.Units.getName(),
                                templateUrl: "units.html",
                                navigation: {
                                    caption: 'units'
                                }
                            },
                            {
                                state: 'packtypes',
                                url: '/packtypes',
                                controller: Dictionary.Controllers.PackTypes.getName(),
                                templateUrl: "packtypes.html",
                                navigation: {
                                    caption: 'packtypes'
                                }
                            },
                            {
                                state: 'types',
                                url: '/types',
                                controller: Dictionary.Controllers.Types.getName(),
                                templateUrl: "types.html",
                                navigation: {
                                    caption: 'types'
                                }
                            },
                            {
                                state: 'categories',
                                url: '/categories',
                                controller: Dictionary.Controllers.Categories.getName(),
                                templateUrl: "categories.html",
                                navigation: {
                                    caption: 'categories'
                                }
                            },
                            {
                                state: 'groups',
                                url: '/groups',
                                controller: Dictionary.Controllers.Groups.getName(),
                                templateUrl: "groups.html",
                                navigation: {
                                    caption: 'groups'
                                }
                            },
                            {
                                state: 'nomenclature',
                                url: '/nomenclature',
                                controller: Dictionary.Controllers.Nomenclature.getName(),
                                templateUrl: "nomenclature.html",
                                navigation: { 
                                    caption: 'nomenclature'
                                }
                            },
                            {
                                state: 'manufacturer',
                                url: '/suppliers',
                                controller: Dictionary.Controllers.Manufacture.getName(),
                                templateUrl: "manufacture.html",
                                navigation: {
                                    caption: 'manufacturers'
                                }
                            },
                            {
                                state: 'brands',
                                url: '/brands',
                                controller: Dictionary.Controllers.Brands.getName(),
                                templateUrl: "brands.html",
                                navigation: {
                                    caption: 'brands'
                                }
                            },
                            {
                                state: 'recipes',
                                url: '/recipes',
                                controller: Dictionary.Controllers.Recipes.getName(),
                                templateUrl: "recipes.html",
                                navigation: {
                                    caption: 'recipes'
                                }
                            }
                        ]
                    },
                    {
                        state: 'services',
                        url: '/services',
                        controller: Dictionary.Controllers.Services.getName(),
                        templateUrl: "services.html",
                        navigation: {
                            caption: 'services'
                        }
                    },
                    {
                        state: 'countries',
                        url: '/countries',
                        controller: Dictionary.Controllers.Countries.getName(),
                        templateUrl: "countries.html",
                        navigation: {
                            caption: 'countries'
                        }
                    },
                    {
                        state: 'currency',
                        url: '/currency',
                        controller: Dictionary.Controllers.Currency.getName(),
                        templateUrl:"currency.html",
                        navigation: {
                            caption: 'currency1'
                        }
                    },
                     {
                        state: 'departaments',
                        url: '/departaments',
                        controller: Dictionary.Controllers.Departaments.getName(),
                        templateUrl:"departaments.html",
                        navigation: {
                            caption: 'departaments'
                        }
                    },
                     {
                        state: 'posts',
                        url: '/posts',
                        controller: Dictionary.Controllers.Posts.getName(),
                        templateUrl:"posts.html",
                        navigation: {
                            caption: 'posts'
                        }
                    },
                    {
                        state: 'any',
                        url: '/any',
                        controller: Dictionary.Controllers.Any.getName(),
                        templateUrl: "any.html",
                        navigation: {
                            caption: 'any'
                        }
                    }

                ]
            }
        ];
    apxDictionary.regRoutes(routes, 'apex');
}