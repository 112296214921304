/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Manufacture extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', Globals.translate, '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $translate, private $timeout) {
            super(arguments, Manufacture.$inject);
            $scope.model = {
                countriesDS: [],
                selectedManufacture: {}
            }
            $scope.editMode = false;
            $scope.components = {
                manufacturGrid: this.initManufacturGrid(),
                events: {
                    add: this.add,
                    edit: this.edit,
                    delete: this.delete,
                    refresh: () => {
                        $scope.components.manufacturGrid.gridView.showLoading();
                        $scope.components.showRefreshInfo = false;
                        $timeout(() => {
                            $scope.components.manufacturGrid.gridView.refresh();
                        });
                    }
                },
                showRefreshInfo: true
            }
            this.initCountries();
        }

        initCountries() {
            this.dictionaryService.getCountries().then(a => {
                this.$scope.model.countriesDS = a.Result;
                return "";
            });
        }


        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Medium;
            var dlg = Core.Utils.ModalForm.openModalForm("manufacture.modal.html", "manufactureModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.refresh();
                }
            });
        }

        edit = () => {
            this.$scope.model.selectedManufacture = this.$scope.components.manufacturGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }
        add = () => {
            this.openModal({ selectedManufacture: {}, countriesDS: this.$scope.model.countriesDS });
        }
        delete = () => {
            this.$scope.model.selectedManufacture = this.$scope.components.manufacturGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteProducer(this.$scope.model.selectedManufacture.ProducerID).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });
        }


        public static manufacturesDataSource = (pageSize: number = null) => {
            var dictionaryService = Core.Utils.Helper.getServiceFromJs("dictionaryService");
            var dataSource = new kendo.data.DataSource({
                 aggregate: [{ field: "ProducerName", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = dictionaryService.getManufactures();
                        fn.then(result => {

                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "ProducerID",
                        fields: {
                            ProducerName: { type: "string" },
                            ProducerNameEnglish: { type: "string" },
                            "Country.CountryNu": { type: "string" },
                            Address: { type: "string" },
                            PostalCode: { type: "string" },
                            Phone: { type: "string" },
                            Person: { type: "string" },
                            Email: { type: "string" },
                            LOwn: { type: "boolean" },
                            Speend: { type: "boolean" }
                        }
                    }
                }
            });
            if (pageSize != null) {
                dataSource.pageSize(pageSize);
            }
            return dataSource;
        }

        initManufacturGrid = () => {
            var grid: Components.IApxGrid = {
                dataSource: Manufacture.manufacturesDataSource(),
                settings: {
                    autoBind: false
                },
                columns: [
                    {
                        field: "ProducerName", titleTranslated: "nameCaption", width: 250,
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.ProducerName ? data.ProducerName.count : 0) #'
                    },
                    { field: "ProducerNameEnglish", titleTranslated: "nameCaptionEn", width: 350 },
                    { field: "Country.CountryNu", titleTranslated: "country", width: 100 },
                    { field: "Address", titleTranslated: "address", width: 250 },
                    { field: "PostalCode", titleTranslated: "postalCode", width: 60 },
                    { field: "Phone", titleTranslated: "phone", width: 90 },
                    { field: "Person", titleTranslated: "cPerson", width: 100 },
                    { field: "Email", titleTranslated: "email", width: 110 },
                    {
                        field: "LOwn", titleTranslated: "LOwn", width: 110,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: this.$translate.instant('isSelected') },
                                    { value: 0, text: this.$translate.instant('isNotSelected') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.LOwn' class='text-center'><i class='fa fa-check text-success'></i></div> <span ng-if='!dataItem.LOwn'></span>"
                    },
                    {
                        field: "Speend", titleTranslated: "Spend", width: 100,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: this.$translate.instant('isSelected') },
                                    { value: 0, text: this.$translate.instant('isNotSelected') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.Speend' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Speend'></span>"
                    },

                ],
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selected = grid.gridView.selectedItem();
                                if (selected)
                                    this.$scope.editMode = true;
                                else
                                    this.$scope.editMode = false;
                            });
                        }
                    }
                ]
            };
            return grid;
        }
    };


    export class ManufactureModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, ManufactureModal.$inject);

            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data.selectedManufacture;
            $scope.countriesDS = data.countriesDS;
            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.ProducerID) {
                    $scope.formName = "მწარმოებლის რედაქტირება";
                    $scope.model.ProducerCountry = $scope.model.Country.CountryId;
                } else {
                    $scope.formName = "მწარმოებლის დამატება";
                }
            });
            $scope.save = this.save;
            $scope.numMask = this.numMask;
            $scope.maskNum = this.maskNum;
            $scope.maskPhone = this.maskPhone;
        }
        maskNum = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;
            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) { // Left / Up / Right / Down Arrow, Backspace, Delete keys //-=chubro=-//
                return;
            }
            if (!/^[0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }
        maskPhone = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;
            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 43) { // Left / Up / Right / Down Arrow, Backspace, Delete , + keys //-=chubro=-//
                return;
            }
            if (!/^[0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }
        numMask = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;

            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 190 || key == 50 || key == 16) { // Left / Up / Right / Down Arrow, Backspace, Delete keys, Dot //-=chubro=-//
                return;
            }
            if (!/^[A-Za-z_0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველი!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            this.dictionaryService.saveProducer(model).then(result => {
                if (result.NewPPCatId !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}