/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Groups extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary) {
            super(arguments, Groups.$inject);
            $scope.components = {
                groupsGrid: this.initGroupsGrid()
            }
        }

        initGroupsGrid = () => {
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getGroups();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                }
            });
            var grid: Components.IApxGrid = {
                dataSource: dataSource,
                columns: [
                    { field: "Name", titleTranslated: "nameCaption" },
                    { field: "HCode", title: "HCode" },
                    { field: "Hccrt", title: "Hccrt" },
                    { field: "ParentID", title: "ParentID" }
                ]
            };
            return grid;
        }
    };
}