/// <reference path='./../_reference.ts' />
module Apex.Dictionary.Directives {
    "use strict";

    export class apxFileChange implements angular.IDirective {
        restrict = 'A';
        link = (scope, element, attrs) => {
            var onChangeHandler = scope.$eval(attrs.apxFileChange);
            element.bind('change', onChangeHandler);
        }
        public static factory() {
            var directive = () => {
                return new apxFileChange();
            };
            return directive;
        }
    }
}