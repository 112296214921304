/// <reference path="./../../../_reference.ts"/>
/// <reference path="../../../../../Apex.WebUI.Core/app/Kendo/ExportDatasource.ts" />
module Apex.Dictionary.Controllers {
	import Kendo = Apex.Core.Kendo;

	export interface IPriceImportScope extends angular.IScope {
		events: {
			download: Function,
			saveDocument: Function,
			tryEnableSave: Function
		},
		//components: {
		//	branchesLookup: Components.IApxLookup
		//},
		other: {
			uploadLocation: string,
			formData: FormData,
			fileAbsent: Boolean,
			//branch: Crm.Interface.ICompanyBranch,
			// trade: Boolean,
			// retail: Boolean,
			// printLabels:Boolean,
			// downloadErrors: Boolean,
			saveDisabled: Boolean
		},
		closeForm: Function,
		changed: Function
	}


	export class PriceImportModal extends Core.Controller {
		public static $inject = [
			Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http
		];
		constructor(private $scope: IPriceImportScope, private data, private dictionaryService: Dictionary.Services.Dictionary, $modalInstance, private toaster, private $http: angular.IHttpService) {
			super(arguments, PriceImportModal.$inject);
			$scope.events = {
				download: this.download,
				saveDocument: this.saveDocument,
				tryEnableSave: this.tryEnableSave
			};
			//$scope.components = { branchesLookup: this.getBranchesLookup() };
			$scope.other = {
				uploadLocation: dictionaryService.filterUrl("/Warehousing/Prices/Document/", null),
				formData: new FormData(),
				fileAbsent: true,
				//branch: null,
				// trade: false,
				// retail: false,
				// printLabels:true,
				// downloadErrors: false,
				saveDisabled: true
			};
			$scope.closeForm = () => {
				$modalInstance.close();
			};
			$scope.changed = () => {
				Core.Utils.Helper.safeApply($scope, () => {
					$scope.other.fileAbsent = false;
					this.$scope.events.tryEnableSave();
				});
			};
			var crmService: Crm.Services.Crm = Core.Utils.Helper.getServiceFromJs('crmService');

			//crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then(res => {
			//	this.$scope.components.branchesLookup.dataSource.data(res.Result);
			//});
		}


		//getBranchesLookup = () => {
		//	var lookUp: Components.IApxLookup = {
		//		dataSource: new kendo.data.DataSource({ data: [] }),
		//		columns: [
		//			{ field: 'Id', hidden: true },
		//			{ field: 'Name', width: 150, titleTranslated: 'nameCaption' },
		//			{ field: 'Code', titleTranslated: 'code', width: 50 },
		//			{ field: 'Company.Code', titleTranslated: 'snFull', width: 60 },
		//			{ field: 'Address.FullAddress', titleTranslated: 'address', width: 100 },
		//		],
		//		valueText: 'Name'
		//	};
		//	return lookUp;
		//};

		tryEnableSave = () => {
			Core.Utils.Helper.safeApply(this.$scope, () => {
				this.$scope.other.saveDisabled = !(/*this.$scope.other.branch && (this.$scope.other.retail || this.$scope.other.trade) && */ !this.$scope.other.fileAbsent);
			});
		}

		saveDocument = () => {
			var fd = new FormData();
			var item = $("#filesinput")[0] as HTMLInputElement;
			fd.append("upload", item.files[0]);
			//if (this.$scope.other.branch && this.$scope.other.branch.Id)
			//	fd.append("BranchId", this.$scope.other.branch.Id);
			// fd.append("PrintLabels", this.$scope.other.printLabels);
			// fd.append("Trade", this.$scope.other.trade);
			// fd.append("Retail", this.$scope.other.retail);
			fd.append("Trade", 'True');
			fd.append("Retail", 'True');
			fd.append("WithMD", 'True');

			//<input type="checkbox" ng-model="other.denyErrors">  <span>{{'DenyErrors' | translate }}</span></input><br />
			fd.append("DownloadErrors", 'True');
			kendo.ui.progress($('.modal-content'), true);
			this.$http.post(
				this.$scope.other.uploadLocation,
				fd,
				{
					transformRequest: angular.identity,
					headers: { 'Content-Type': undefined },
					responseType: "arraybuffer"
				}
			).then((success)=>{
				kendo.ui.progress($('.modal-content'), false);
				if (success.status == 204) {
					this.toaster.pop("success", "", "ფაილი წარმატებით აიტვირთა!");
					this.$scope.closeForm();
				} else {
					this.saveFile(success.data, "result.xlsx");
					this.toaster.pop("warning", "შეცდომა", "ფაილი შეიცავს შეცდომებს");
				}
			},(error)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.toaster.pop("error", "შეცდომა", JSON.stringify(error || ""));
			});
		}

		download = () => {
			kendo.ui.progress($('.modal-content'), true);
			this.$http({
				url: this.$scope.other.uploadLocation,
				method: "GET",
				headers: {
					'Content-type': "application/json"
				},
				responseType: "arraybuffer"
			}).then((success)=>{
					kendo.ui.progress($('.modal-content'), false);
					this.saveFile(success.data, "result.xlsx");
			}, (error)=>{
				kendo.ui.progress($('.modal-content'), false);
				this.toaster.pop("error", "შეცდომა", error.Message);
			});
		}

		private saveFile = (content, filename, contentType: string = null) => {
			if (!contentType) contentType = "application/octet-stream";
			var a = <any>document.createElement("a");
			var blob = new Blob([content], { 'type': contentType });
			a.href = window.URL.createObjectURL(blob);
			a.target = "_blank";
			a.download = filename;
			a.style = "visibility:hidden";
			document.body.appendChild(a);
			a.click();
			setTimeout(() => { document.body.removeChild(a) }, 500);
		}
	}
}