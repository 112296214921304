/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {

    export interface DepartamentsScope extends angular.IScope {
        model: {
            dataSource?: kendo.data.TreeListDataSource
        };
        Components: {
            DepartamentsTreeList: Apex.Components.IApxTreeList
            Data?: {
                selectedDep?: any,
                selectedID: number,
                selectedHrclvl: number
            };
            selected?: boolean;
            deleteMode?: boolean;
        };
        events: {
            refresh: Function,
            add: Function,
            addIn: Function,
            edit: Function,
            delete: Function,
            expand: Function,
            collapse: Function,
            setDefault: Function,
            setCanceled: Function
        }
        isCollapsed: boolean;
        Deptreelist: any;
        isDefault: boolean;
    }
    export class Departaments extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', Globals.toaster, Globals.translate
        ];
        constructor(private $scope: DepartamentsScope, private dictionaryService: Dictionary.Services.Dictionary, private toaster, private $translate) {
            super(arguments, Departaments.$inject);
            $scope.isCollapsed = false;
            $scope.model = {
                dataSource: null
            },
                $scope.Components = {
                    DepartamentsTreeList: this.initDepTreeList(),
                    Data: {
                        selectedDep: null,
                        selectedID: null,
                        selectedHrclvl: null
                    },
                    selected: false,
                    deleteMode: false
                },
                $scope.events = {
                    refresh: this.refresh,
                    add: this.add,
                    addIn: this.addIn,
                    edit: this.edit,
                    delete: this.delete,
                    expand: this.expand,
                    collapse: this.collapse,
                    setDefault: this.setDefault,
                    setCanceled: this.setCanceled
                },
                this.refresh();
        }
        add = () => {
            delete this.$scope.Components.Data.selectedDep;
            delete this.$scope.Components.Data.selectedID;
            this.openModal(this.$scope.Components.Data);
        };
        addIn = () => {
            delete this.$scope.Components.Data.selectedDep;
            var selected = this.$scope.Components.DepartamentsTreeList.treeList.dataItem(this.$scope.Components.DepartamentsTreeList.treeList.select());
            this.$scope.Components.Data.selectedID = (<any>selected).Codeid;

            if (!this.$scope.Components.Data.selectedID)
                return;
            else
                this.openModal(this.$scope.Components.Data);
        };
        edit = () => {
            this.$scope.Components.Data.selectedDep = this.$scope.Components.DepartamentsTreeList.treeList.dataItem(this.$scope.Components.DepartamentsTreeList.treeList.select());
            delete this.$scope.Components.Data.selectedID;
            this.openModal(this.$scope.Components.Data);
        };
        delete = () => {
            this.$scope.Components.Data.selectedDep = this.$scope.Components.DepartamentsTreeList.treeList.dataItem(this.$scope.Components.DepartamentsTreeList.treeList.select());
            if (!this.$scope.Components.Data || this.$scope.Components.deleteMode == true)
                return;
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteDepartament(this.$scope.Components.Data.selectedDep.Codeid).then(r => {
                    if (!!r.statusCode) {
                        return;
                    } else {
                        this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successDeleted'));
                        this.$scope.events.refresh();
                    }
                });
            });
        };
        setDefault = () => {
            this.$scope.Components.Data.selectedDep = this.$scope.Components.DepartamentsTreeList.treeList.dataItem(this.$scope.Components.DepartamentsTreeList.treeList.select());
            if (this.$scope.isDefault == false)
                return;
            this.$scope.Components.Data.selectedDep.Mgrid = this.$scope.Components.Data.selectedDep.parentId;
            this.$scope.Components.Data.selectedDep.Defval = true;
            this.dictionaryService.saveDepartaments(this.$scope.Components.Data.selectedDep).then(r => {
                if (r)
                    this.refresh();
            });
        };
        setCanceled = () => {
            this.$scope.Components.Data.selectedDep = this.$scope.Components.DepartamentsTreeList.treeList.dataItem(this.$scope.Components.DepartamentsTreeList.treeList.select());
            if (this.$scope.Components.Data.selectedDep.Canceled == false) {
                this.$scope.Components.Data.selectedDep.Canceled = true;
                if (this.$scope.Components.Data.selectedDep.Defval == true)
                    this.$scope.Components.Data.selectedDep.Defval = false;
            }
            else {
                this.$scope.Components.Data.selectedDep.Canceled = false;
            }
            this.$scope.Components.Data.selectedDep.Mgrid = this.$scope.Components.Data.selectedDep.parentId;
            this.dictionaryService.saveDepartaments(this.$scope.Components.Data.selectedDep).then(r => {
                if (r)
                    this.refresh();
            });
        };

        refresh = () => {
            this.dictionaryService.getDepartments().then(result => {
                for (var index = 0; index < result.Result.length; index++) {
                    if (!result.Result[index].Mgrid) {
                        result.Result[index].Mgrid = null;
                    }
                }
                this.$scope.Components.DepartamentsTreeList.treeList.setDataSource(this.convertToTreeListDataSource(result.Result)); 
            });
        }
        openModal = (data) => {
            var dlg = Core.Utils.ModalForm.openModalForm("departaments.modal.html", DepModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result == true) {
                    this.$scope.events.refresh();
                }
            });
        }
        expand = () => {
            this.$scope.Deptreelist = this.$scope.Components.DepartamentsTreeList.treeList;
            var rowIndex = 0;
            var rows = this.$scope.Deptreelist.element.find("tr.k-treelist-group");
            if (rows && rows.length > 0) {
                for (var i = 0; i < rows.length; i++) {
                    this.$scope.Deptreelist.expand(rows[i]);
                }
            }

            this.$scope.isCollapsed = false;
        }
        collapse = () => {
            this.$scope.Deptreelist = this.$scope.Components.DepartamentsTreeList.treeList;
            var rowIndex = 0;
            var row = this.$scope.Deptreelist.content.find("tr.k-treelist-group");
            for (var i = 0; i < row.length; i++) {
                this.$scope.Deptreelist.collapse(row[i]);
            }
            this.$scope.isCollapsed = true;
        }
        convertToTreeListDataSource = (data: any): kendo.data.TreeListDataSource => {
            return new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        expanded: true,
                        id: "Codeid",
                        fields: {
                            parentId: { field: "Mgrid", nullable: true }
                        },
                    }
                }
            });
        }
        initDepTreeList = () => {
            var _treeList: Apex.Components.IApxTreeList = {
                options: {
                    columns: this.DepTreeListColumns,
                    dataSource: {
                        data: [],
                        schema: {
                            model: {
                                expanded: true,
                                id: "Codeid",
                                parentId: "Mgrid",
                                fields: {
                                    Mgrid: { nullable: true, type: "number" },
                                    Phone: { nullable: true, type: "string" },
                                    Address: { nullable: true, type: "string" },
                                    Hcode: { nullable: true, type: "string" },
                                    Hrccrt: { nullable: true, type: "string" }
                                }
                            },
                        }
                    },
                    change: this.depTreeListSelectedChange,
                    editable: {
                        mode: "inline"
                    },
                    filterable: true,
                    resizable: true,
                    sortable: { mode: "single" },
                    selectable: true
                },
            }
            return _treeList;
        }
        depTreeListSelectedChange = (args: any) => {
            Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                var selectedDep = (<JQuery>args.sender.select()).data().$scope.dataItem; 
                if (selectedDep)
                    this.$scope.Components.selected = true;
                else
                    this.$scope.Components.selected = false;
                if (selectedDep.hasChildren)
                    this.$scope.Components.deleteMode = true;
                else
                    this.$scope.Components.deleteMode = false;
                if (selectedDep.Canceled == true)
                    this.$scope.isDefault = false;
                else
                    this.$scope.isDefault = true;
            });
        }
        DepTreeListColumns: Apex.Core.Interfaces.ITreeListColumn[] = [
            { field: "Codeid", hidden: true, titleTranslated: "code", width: 50 },
            {
                field: "Hccrt", titleTranslated: "hccrt", width: 50,
                template: "<span ng-if='dataItem.Defval' class='def-color'>{{dataItem.Hccrt}}</span> <span ng-if='!dataItem.Defval'>{{dataItem.Hccrt}}</span>"
            },
            {
                field: "Hname", titleTranslated: "caption", width: 150,
                template: "<span ng-if='dataItem.Defval' class='def-color''>{{dataItem.Hname}}</span><span ng-if='!dataItem.Defval'>{{dataItem.Hname}}</span>"
            },
            {
                field: "Phone", titleTranslated: "phone", width: 50,
                template: "<span ng-if='dataItem.Defval' class='def-color'>{{dataItem.Phone}}</span> <span ng-if='!dataItem.Defval'>{{dataItem.Phone}}</span>"
            },
            {
                field: "Address", titleTranslated: "address", width: 100,
                template: "<span ng-if='dataItem.Defval' class='def-color'>{{dataItem.Address}}</span> <span ng-if='!dataItem.Defval'>{{dataItem.Address}}</span>"
            },
            {
                field: "Canceled", titleTranslated: "Canceled", width: 25,
                template: "<div ng-if='dataItem.Canceled' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Canceled'></span>"
            },
            {
                field: "Hrclvl", titleTranslated: "hccrt", width: 20,
                template: "<span ng-if='dataItem.Defval' class='def-color'>{{dataItem.Hcode}}</span> <span ng-if='!dataItem.Defval'>{{dataItem.Hcode}}</span>"
            },
        ];
    };







    export interface DepModalScope extends angular.IScope {
        model: {
            departament: any,
            codeID: number
        };
        Components: {
            citys: any[]
        };
        events: {
            save: Function,
            close: Function
        }
        AddMode: boolean;
        formName: string;
    }
    export class DepModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http
        ];
        constructor(private $scope: DepModalScope, data: any, private dictionaryService: Dictionary.Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService) {
            super(arguments, DepModal.$inject);
            $scope.AddMode = false;

            $scope.model = {
                departament: data.selectedDep,
                codeID: data.selectedID
            }
            $scope.Components = {
                citys: []
            }
            $scope.events = {
                save: this.save,
                close: this.closeForm
            }

            if (data.selectedDep) {
                $scope.AddMode = false;
                $scope.formName = "დეპარტამენტის რედაქტირება";
            } else {
                $scope.AddMode = true;
                $scope.formName = "დეპარტამენტის დამატება";
            }
            this.getCitys();
        }

        getCitys() {
            this.dictionaryService.getCitysTree().then(r => {
                this.$scope.Components.citys = r.Result;
            });
        }
        closeForm = () => {
            this.$scope.model.departament == null;
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            if (!this.$scope.model.departament.Hname || !this.$scope.model.departament.Cityid) {
                this.toaster.pop('error', 'ყურადღება', 'შეავსეთ აუცილებელი ველები!');
                return;
            }
            if (this.$scope.AddMode) {
                if (!this.$scope.model.codeID) {
                    this.dictionaryService.saveDepartaments(this.$scope.model.departament);
                }
                else {
                    this.$scope.model.departament.Mgrid = this.$scope.model.codeID;
                    this.dictionaryService.saveDepartaments(this.$scope.model.departament);
                }
            }
            else {
                this.$scope.model.departament.Mgrid = this.$scope.model.departament.parentId;
                this.dictionaryService.saveDepartaments(this.$scope.model.departament);
            }
            this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
            this.$uibModalInstance.close(true);
        }
    }
}