/// <reference path="../_reference.ts" />
module Apex.Dictionary.Services {
    'use strict';
    export class Dictionary extends Core.Service {
        goodsQueryUrl = '/Common/Dictionary/Goods/Query/';

        mgrIdAny = 'BF0AEBA5-2FD5-48FE-9ED8-6DC4094A29DD';
        mgrIdCrmOrders = 'b0b19794-7d8d-4af2-9c74-848304d8e18a';

        getCurrencies(): angular.IPromise<any> {
            return this.httpGet('/Accounting/Currency?jsconfig=dh:iso8601');
        }


        getHcategory(): angular.IPromise<Interfaces.ServiceTypes.ISimpleListResult<Interfaces.ServiceTypes.IHCategory>> {
            return this.httpGet("/Common/Dictionary/HCategories/");
        }

        getCurrenciesRates(currencyID, startDate, endDate): angular.IPromise<any> {
            var params = {
                nm: 'CurID={0}&Date={1}&EndDate={2}&jsconfig=dh:iso8601',
                pr: [currencyID, startDate, endDate]
            }
            return this.httpGet('/Accounting/Currency/Rates', params);
        }

        getCountries(): angular.IPromise<any> {
            return this.httpGet("/Common/Country/");
        }

        getCities(countryID): angular.IPromise<any> {
            return this.httpGet("/Common/Country/" + countryID + "/City/");
        }

        getBrands() {
            return this.httpGet("/Common/Good/Brand");
        }

        getCategories(): angular.IPromise<Interfaces.ServiceTypes.ISimpleListResult<Interfaces.ServiceTypes.ICategory>> {
            return this.httpGet("/Common/Category/");
        }

        getGroups(): angular.IPromise<Interfaces.ServiceTypes.ISimpleListResult<Interfaces.ServiceTypes.IHCategory>> {
            return this.httpGet("/Common/Dictionary/HCategories/");
        }

        getManufactures(): angular.IPromise<Interfaces.ServiceTypes.ISimpleListResult<Interfaces.ServiceTypes.IProducer>> {
            return this.httpGet("/Common/Dictionary/Producers/");
        }

        getTypes(): angular.IPromise<Interfaces.ServiceTypes.ISimpleListResult<Interfaces.ServiceTypes.IGoodType>> {
            return this.httpGet("/Common/Dictionary/GoodType");
        }

        getPacktypes() {
            return this.httpGet("/Common/PackType/");
        }

        getProductsUnits(): angular.IPromise<any> {
            return this.httpGet("/Common/ProductUnit/");
        }

        getIntCode() {
            return this.httpGet("/Common/IntCode/");
        }

        getFixVal(mgrId: string): angular.IPromise<any> {
            return this.httpGet("/Core/FixVal?mgrId=" + mgrId);
        }

        getFixValChildHierarchy(mgrId: string): angular.IPromise<any> {
            return this.httpGet("/Core/FixVal/ChildHierarchy?mgrId=" + mgrId);
        }

        getFixValDtAny(mgrId: string): angular.IPromise<any> {
            return this.httpGet("/Common/Dictionary/DTAny/" + mgrId);
        }
        saveAnyDetails(anyDetails) {
            return this.httpPost("/Common/Dictionary/DTAny/", { DtAny: anyDetails });
        }

        deleteAnyDetails(codeId): angular.IPromise<any> {
            return this.httpDelete("/Common/Dictionary/DTAny/ById/" + codeId);
        }

        deleteProducer(producerID) {
            return this.httpDelete("/Common/Producer/" + producerID);
        }

        saveProducer(producer) {
            return this.httpPost("/Common/Producer/", { Producer: producer });
        }

        getServices() {
            return this.httpGet("/Accounting/Services/");
        }

        getProdPp() {
            return this.httpGet("/Common/Dictionary/Goods/Query/");
        }
        saveProdpp(prodppmodel, exInfo, priceInfo) {
            return this.httpPost("/Common/Dictionary/Goods/", { Good: prodppmodel, ExInfo: exInfo, PriceInfo: priceInfo });
        }
        deleteProdpp(id) {

            return this.httpDelete("/Common/Dictionary/Goods/" + id)
        }
        saveService(serviceModel) {
            return this.httpPost("/Accounting/Services/", { Service: serviceModel });
        }

        deleteService(serviceID) {
            return this.httpDelete("/Accounting/Services/" + serviceID);
        }
        savePackType(packType) {
            return this.httpPost("/Common/PackType/", { PackType: packType });
        }
        deletePackType(packTypeId) {
            return this.httpDelete("/Common/PackType/" + packTypeId);
        }

        saveProductUnit(unit) {
            return this.httpPost("/Common/ProductUnit/", { ProductUnit: unit });
        }
        deleteProductUnit(unitId) {
            return this.httpDelete("/Common/ProductUnit/" + unitId);
        }

        saveType(type) {
            return this.httpPost("/Common/GoodType/", { GoodType: type });
        }
        deleteType(typeId) {
            return this.httpDelete("/Common/GoodType/" + typeId);
        }

        saveBrand(brand) {
            return this.httpPost("/Common/Good/Brand", { Brand: brand });
        }
        deleteBrand(brandID) {
            return this.httpDelete("/Common/Good/Brand/" + brandID);
        }
        saveCategory(category) {
            return this.httpPost("/Common/Category/", { Category: category });
        }

        deleteCategory(categoryId) {
            return this.httpDelete("/Common/Category/" + categoryId);
        }
        saveIntCode(intCode) {
            return this.httpPost("/Common/IntCode/", { IntCode: intCode });
        }
        updateIntCode(intCode) {
            return this.httpPut("/Common/IntCode/", { IntCode: intCode });
        }

        deleteIntCode(intCodeId) {
            return this.httpDelete("/Common/IntCode/" + encodeURIComponent(intCodeId));
        }
        saveCountry(countryModel) {
            return this.httpPost("/Common/Country/", { Country: countryModel });
        }
        deleteCountry(countryId) {
            return this.httpDelete("/Common/Country/" + countryId);
        }
        saveCity(cityModel) {
            return this.httpPost("/Common/City/", { City: cityModel });
        }
        deleteCity(cityId) {
            return this.httpDelete("/Common/City/" + cityId);
        }
        getGoodExtendedInfo(prodppId, bCode) {

            var params = {
                nm: 'ProdPPId={0}&BCode={1}',
                pr: [prodppId, bCode]
            }
            return this.httpGet("/Common/GoodExtendedInfo", params);
        }
        getExiceGoods() {
            return this.httpGet("/RS/ExciseGoods");
        }


        getGoodInfo(prodppId) {
            var params = {
                nm: 'ID={0}&IncludeExtendedInfo={1}',
                pr: [prodppId, true]
            }
            return this.httpGet("/Common/Dictionary/Goods/Single", params);
        }

        getProductRecipes(categoryId: string, producerId: string, spend: boolean = null): angular.IPromise<{ Result: Array<Interfaces.IProductionGood> }> {
            var requestUrl = "/Common/Dictionary/ProductRecipes/Products?";

            if (spend != undefined && spend != null) {
                requestUrl += "Spend=" + spend;
            }

            if (categoryId != undefined && categoryId != null && categoryId.trim().length > 0) {
                requestUrl += "&CategoryId=" + categoryId;
            }
            if (producerId != undefined && producerId != null && producerId.trim().length > 0) {
                requestUrl += "&ProducerId=" + producerId;
            }

            return this.httpGet(requestUrl);
        }

        getRecipeCost(prodppId: string): angular.IPromise<{ Result: Array<Interfaces.IRecipeCost> }> {
            return this.httpGet('/Common/Dictionary/RecipeCost/' + prodppId);
        }

        saveRecipeDetails(recipeList: Array<Interfaces.IRecipeCost>): angular.IPromise<any> {
            return this.httpPost("/Common/Dictionary/RecipeDetails", { RecipeList: recipeList });
        }
        getCitysTree(): angular.IPromise<any> {
            return this.httpGet('/AGD/City');
        };
        getDepartments(): angular.IPromise<any> {
            return this.httpGet('/AGD/Departaments');
        };
        saveDepartaments(departament: any): angular.IPromise<any> {
            return this.httpPost("/AGD/Departaments", { Departaments: departament });
        }
        deleteDepartament(Id) {
            return this.httpDelete("/AGD/Departaments/" + Id);
        }

        getPost(): angular.IPromise<any> {
            return this.httpGet('/AGD/Post');
        }
        savePost(post: any): angular.IPromise<any> {
            return this.httpPost("/AGD/Post", { Post: post });
        }
        deletePost(Id) {
            return this.httpDelete("/AGD/Post/" + Id);
        }
    }
}