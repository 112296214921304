/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export interface UnitsScope extends angular.IScope {
        model: {
            selectedUnit?: any

        };
        components: {
            unitsGrid: Apex.Components.IApxGrid,
            showRefreshInfo: boolean
        },
        events: {
            add: Function,
            delete: Function,
            edit: Function,
            refresh: Function
        }
        isActive: boolean
    }
    export class Units extends Core.Controller {
        public static $inject = [
            Globals.scope, Globals.translate, Globals.toaster, 'dictionaryService'
        ];
        constructor(private $scope: UnitsScope, private $translate, private toaster: any, private dictionaryService: Dictionary.Services.Dictionary) {
            super(arguments, Units.$inject);
            $scope.model = {
                selectedUnit: {}
            }
            $scope.isActive = true;
            $scope.components = {
                unitsGrid: this.initUnitGrid(),
                showRefreshInfo: true
            }
            $scope.events = {
                add: this.add,
                delete: this.delete,
                edit: this.edit,
                refresh: this.refresh
            }
        }
        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("units.modal.html", "unitModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.events.refresh();
                }
            });
        }
        edit = () => {
            this.$scope.model.selectedUnit = this.$scope.components.unitsGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }
        add = () => {
            this.openModal({});
        }
        delete = () => {
            this.$scope.model.selectedUnit = this.$scope.components.unitsGrid.gridView.selectedItem();
            var confirmationDialog = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            confirmationDialog.result.then(() => {
                
                this.dictionaryService.deleteProductUnit(this.$scope.model.selectedUnit.UnitId).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('success', 'ყურადღება', 'წარმატებით წაიშალა') });
                        this.$scope.components.unitsGrid.grid.removeRow(this.$scope.components.unitsGrid.grid.select());
                    }
                });
            });
        }
        refresh = () => {
            this.$scope.components.unitsGrid.gridView.showLoading();
            this.$scope.components.unitsGrid.gridView.refresh();
            this.$scope.components.unitsGrid.dataSource.read();
            this.$scope.components.showRefreshInfo = false;
        }
        public static unitsDataSource = (pageSize: number = null) => {
            var dictionaryService = Core.Utils.Helper.getServiceFromJs("dictionaryService");
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        var fn: any = dictionaryService.getProductsUnits();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "UnitId",
                        fields: {
                            UnitNu: { type: "string" },
                            UnitNe: { type: "string" }
                        }
                    }
                }
            });
            if (pageSize != null) {
                dataSource.pageSize(pageSize);
            }
            return dataSource;
        }

        initUnitGrid = () => {

            var grid: Components.IApxGrid = {
                dataSource: Units.unitsDataSource(),
                columns: [
                    { field: "UnitNu", titleTranslated: "nameCaption" },
                    { field: "UnitNe", titleTranslated: "nameCaptionEn" }
                ],
                settings: {
                    autoBind: false
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            var selectedRow: any = grid.grid.dataItem(grid.grid.select());
                            if (selectedRow != null && selectedRow != undefined) {
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.isActive = false);
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.isActive = false);
                            }
                            else {
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.isActive = true);
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.isActive = true);
                            }
                        }
                    }
                ]
            };
            return grid;
        }
    }

    export interface UnitModalScope extends angular.IScope {
        model: {
            Data?: any
        };
        events: {
            save: Function,
            closeForm: Function
        }
        setForm: Function,
        formName: string,
        formScope: any
    }
    export class UnitModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: UnitModalScope, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, UnitModal.$inject);
            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };

            $scope.model = {
                Data: data.selectedUnit
            },
                $timeout(() => {
                    if ($scope.model.Data) {
                        $scope.formName = "ზომის ერთეულის რედაქტირება";
                    } else {
                        $scope.formName = "ზომის ერთეულის დამატება";
                    }
                });
            $scope.events = {
                save: this.save,
                closeForm: this.closeForm
            }
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველი!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model.Data;
            if (model) {
                this.dictionaryService.saveProductUnit(model).then(result => {
                    if (_.isNumber(result.Result)) {
                        this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$uibModalInstance.close(true);
                    }
                    return " ";
                });
            }
        }
    }

}