/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Utils {
    "use strict";
    export class ProdppRegistration {
        public static createProdpp(callback: Function = null) {
            var modalSize = Core.Utils.ModalSize.Medium;
            var dlg = Core.Utils.ModalForm.openModalForm("prodpp.modal.html", "prodppModal" + 'Controller', {}, modalSize);
            dlg.result.then(result => {
                if (result !== undefined && callback !== undefined && callback !== null) {
                    callback();
                }
            });
        }

    }
}