/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Services extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary) {
            super(arguments, Services.$inject);
            $scope.model = {
                selectedService: {}
            }
            $scope.editMode = false;
            $scope.components = {
                servicesGrid: this.initSercicesGrid(),
                events: {
                    add: this.addService,
                    edit: this.editService,
                    delete: this.deleteService,
                    refresh: () => {
                        $scope.components.servicesGrid.gridView.showLoading();
                        $scope.components.servicesGrid.gridView.refresh();
                        $scope.components.showRefreshInfo = false;
                    }
                },
                showRefreshInfo: true
            }
            this.onCompanyChange = (newCompany, oldCompany) => {
                $scope.components.events.refresh();
            }
        }

        addService = () => {
            this.$scope.model.selectedService = {};
            this.openModal(this.$scope.model);
        }

        editService = () => {
            this.$scope.model.selectedService = this.$scope.components.servicesGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }

        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("service.modal.html", "serviceModalController", data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined" && result) {
                    this.$scope.editMode = false;
                    this.$scope.components.events.refresh();
                }
            });
        }
        deleteService = () => {
            this.$scope.model.selectedService = this.$scope.components.servicesGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteService(this.$scope.model.selectedService.ServiceID).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                        this.$scope.editMode = false;
                    }
                });
            });
        }
        initSercicesGrid = () => {
            var dataSource = new kendo.data.DataSource({
                aggregate: [{ field: "Code", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getServices();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        fields: {
                            Code: { type: "string" },
                            Name: { type: "string" },
                            NameEn: { type: "string" },
                            Price: { type: "number" },
                            Vat: { type: "number" },
                            Spend: { type: "boolean" },
                        }
                    }
                }
            });
            var grid: Components.IApxGrid = {
                dataSource: dataSource,
                columns: [
                    {
                        field: "Code", titleTranslated: "code", width: 100,
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.Code ? data.Code.count : 0) #'
                    },
                    { field: "Name", titleTranslated: "nameCaption", width: 250 },
                    { field: "NameEn", titleTranslated: "nameCaptionEn", width: 250 },
                    { field: "Price", titleTranslated: "price", format: Core.Kendo.Format.Price4, width: 120, filterOperator: 'eq' },
                    {
                        field: "Vat", titleTranslated: "vat", width: 100, format: Core.Kendo.Format.Percent, filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: 'ყველა' },
                                    { value: 0.18, text: '18%' },
                                    { value: 0, text: '0%' }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        }
                    },
                    {
                        field: "Spend", titleTranslated: "Spend", width: 70, filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: 'ყველა' },
                                    { value: 1, text: 'მონიშნული' },
                                    { value: 0, text: 'მოუნიშნავი' }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.Spend' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Spend'></span>"
                    },
                ],
                settings: { autoBind: false },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selected = grid.gridView.selectedItem();
                                if (selected)
                                    this.$scope.editMode = true;
                                else
                                    this.$scope.editMode = false;
                            });
                        }
                    }
                ]
            };
            return grid;
        }
    };

    export class ServiceModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, ServiceModal.$inject);

            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data.selectedService;

            $scope.components = {
                vatTypeDS: [{ val: 0, title: "ჩვეულებრივი" }, { val: 1, title: "დაუბეგრავი" }]
            };


            if ($scope.model.Vat !== undefined) {
                if ($scope.model.Vat * 100 === 18) {
                    this.$scope.model.VatType = 0;
                } else {
                    this.$scope.model.VatType = 1;
                }
            } else {
                this.$scope.model.VatType = 0;

            }

            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.ServiceID) {
                    $scope.formName = "მომსახურების რედაქტირება";
                } else {
                    $scope.formName = "მომსახურების დამატება";
                }
            });
            $scope.save = this.save;


            $scope.dirtyModel = {
                vatText: ''
            }

            $scope.changeVat = this.changeVat;
            $scope.numMask = this.numMask;
            this.changeVat();

        }
        numMask = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;
            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 190) { // Left / Up / Right / Down Arrow, Backspace, Delete keys, Dot //-=chubro=-//
                return;
            }
            if (!/^[A-Za-z_0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }

        changeVat = () => {
            switch (this.$scope.model.VatType) {
                case 0:
                    this.$scope.model.Vat = 0.18;
                    break;
                default:
                    this.$scope.model.Vat = 0;
                    break;
            }
            this.$scope.dirtyModel.vatText = this.$scope.model.Vat * 100 + "%";
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {

            if (!this.$scope.model.Name) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველი!');
                return;
            }
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            this.dictionaryService.saveService(model).then(result => {
                if (result.ServiceId !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}