/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class PackTypes extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $timeout) {
            super(arguments, PackTypes.$inject);
            $scope.model = {
                selectedPackType: {}
            }
            $scope.editMode = false;
            $scope.components = {
                packtypesGrid: this.initPacktypesGrid(),
                events: {
                    add: this.add,
                    delete: this.delete,
                    edit: this.edit,
                    refresh: () => {
                        $scope.components.packtypesGrid.gridView.showLoading();
                        $scope.components.showRefreshInfo = false;
                        $timeout(() => {
                            $scope.components.packtypesGrid.gridView.refresh();
                        });
                    }
                },
                showRefreshInfo: true
            }
        }
        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("packtype.modal.html", "packtypeModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.refresh();
                }
            });
        }
        edit = () => {
            this.$scope.model.selectedPackType = this.$scope.components.packtypesGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }
        add = () => { 
            this.$scope.model.selectedPackType = {};
            this.openModal(this.$scope.model);
        }
        delete = () => {
            this.$scope.model.selectedPackType = this.$scope.components.packtypesGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deletePackType(this.$scope.model.selectedPackType.PackTypeID).then((result: any) => {
                    if (!!result.statusCode) {
                        // toaster.pop('error', "შეცდომა!", translate.instant('cannotDelete'));
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });
        }
        initPacktypesGrid = () => {
            var dataSource = new kendo.data.DataSource({
                aggregate: [{ field: "PackTypeID", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getPacktypes();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "PackTypeID",
                        fields: {
                            PackTypeNe: { type: "string" },
                            PackTypeNu: { type: "string" }
                        }
                    }
                }
            });
            var grid: Components.IApxGrid = {
                dataSource: dataSource,
                columns: [
                    { field: "PackTypeNu", titleTranslated: "nameCaption", footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.PackTypeID ? data.PackTypeID.count : 0) #'  },
                    { field: "PackTypeNe", titleTranslated: "nameCaptionEn" }
                ],
                settings: {
                    autoBind: false
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selected = grid.gridView.selectedItem();
                                if (selected) {
                                    this.$scope.editMode = true;
                                }
                                else
                                    this.$scope.editMode = false;
                            });
                        }
                    }
                ]
            };
            return grid;
        }
    };

    export class PacktypeModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, PacktypeModal.$inject);
            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data.selectedPackType;
            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.PackTypeID) {
                    $scope.formName = "შეფუთვის ტიპის რედაქტირება";
                } else {
                    $scope.formName = "შეფუთვის ტიპის დამატება";
                }
            });
            $scope.save = this.save;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველი!');
                return;
            }
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;
            this.dictionaryService.savePackType(model).then(result => {
                if (_.isNumber(result.Result)) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}