/// <reference path="./../../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Nomenclature extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $timeout) {
            super(arguments, Nomenclature.$inject);
            $scope.model = {
                selectedNomenclature: {}
            }
            $scope.editMode = false;
            $scope.components = {
                nomenclatureGrid: this.initNomenclatureGrid(),
                events: {
                    add: this.add,
                    delete: this.delete,
                    edit: this.edit,
                    refresh: () => {
                        $scope.components.nomenclatureGrid.gridView.showLoading();
                        $scope.components.showRefreshInfo = false;
                        $timeout(() => {
                            $scope.components.nomenclatureGrid.gridView.refresh();
                        });
                    }
                },
                showRefreshInfo: true
            }
        }

        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("nomenclature.modal.html", "nomenclatureModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.refresh();
                }
            });
        }
        edit = () => {
            this.$scope.model.selectedNomenclature = this.$scope.components.nomenclatureGrid.gridView.selectedItem();
            this.openModal(this.$scope.model);
        }
        add = () => {
            this.$scope.model.selectedNomenclature = {};
            this.openModal(this.$scope.model);
        }
        delete = () => {
            this.$scope.model.selectedNomenclature = this.$scope.components.nomenclatureGrid.gridView.selectedItem();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteIntCode(this.$scope.model.selectedNomenclature.IntCodeID).then((result: any) => {
                    if (!!result.statusCode) {
                        return;
                    }
                    else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });
        }

        initNomenclatureGrid = () => {
            var dataSource = new kendo.data.DataSource({
                aggregate: [{ field: "IntCodeID", aggregate: "count" }],
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getIntCode();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "IntCodeID",
                        fields: {
                            IntCodeID: { type: "string" },
                            Name: { type: "string" },
                            NameEn: { type: "string" },
                            Vat: { type: "number" },
                            Tax1: { type: "number" },
                            TaxK: { type: "number" },
                            TaxMethod: { type: "number" }
                        }
                    }
                }
            });
            var grid: Components.IApxGrid = {
                dataSource: dataSource,
                columns: [
                    {
                        field: "IntCodeID", titleTranslated: "intCode", width: 200,
                        footerTemplate: '<span translate=count1> </span> #= kendo.toString(data.IntCodeID ? data.IntCodeID.count : 0) #'
                    },
                    { field: "Name", titleTranslated: "nameCaption", width: 400 },
                    { field: "NameEn", titleTranslated: "nameCaptionEn", width: 400 },
                    {
                        field: "Vat", titleTranslated: "vat", format: Core.Kendo.Format.Percent, width: 100, filterOperator: 'eq', filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: 'ყველა' },
                                    { value: 0.18, text: '18%' },
                                    { value: 0, text: '0%' }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        }
                    },
                    { field: "Tax1", titleTranslated: "tax1", format: Core.Kendo.Format.Percent, width: 100 },
                    { field: "TaxK", titleTranslated: "taxK", format: Core.Kendo.Format.Price4, width: 100, filterOperator: 'eq' },
                    { field: "TaxMethod", titleTranslated: "taxMethod", format: Core.Kendo.Format.Number, width: 100, filterOperator: 'eq' }
                ],
                settings: {
                    autoBind: false
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                var selected = grid.gridView.selectedItem();
                                if (selected)
                                    this.$scope.editMode = true;
                                else
                                    this.$scope.editMode = false;
                            });
                        }
                    }
                ]
            };
            return grid;
        }

    };



    export class NomenclatureModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: any, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, NomenclatureModal.$inject);
            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.model = data.selectedNomenclature;
            $scope.codeIDmodel = jQuery.extend(true, {}, $scope.model);
            $scope.closeForm = this.closeForm;
            $timeout(() => {
                if (!!$scope.model.IntCodeID) {
                    $scope.model.Tax1 = $scope.model.Tax1 * 100; 
                    $scope.formName = "ნომენკლატურის რედაქტირება";
                } else {
                    $scope.model.TaxMethod = 1;
                    $scope.formName = "ნომენკლატურის დამატება";
                }
            });
            $scope.save = this.save;


            $scope.components = {
                vatTypeDS: [{ val: 0, title: "ჩვეულებრივი" }, { val: 1, title: "დაუბეგრავი" }]
            };


            if ($scope.model.Vat !== undefined) {
                if ($scope.model.Vat * 100 === 18) {
                    this.$scope.model.VatType = 0;
                } else {
                    this.$scope.model.VatType = 1;
                }
            } else {
                this.$scope.model.VatType = 0;

            }

            $scope.dirtyModel = {
                vatText: ''
            }

            $scope.changeVat = this.changeVat;
            this.changeVat();
            $scope.numMask = this.numMask;
            $scope.numMask2 = this.numMask2;
        }
        numMask = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;

            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 190 || key == 50 || key == 16) { // Left / Up / Right / Down Arrow, Backspace, Delete keys, Dot //-=chubro=-//
                return;
            }
            if (!/^[A-Za-z_0-9]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }

        numMask2 = (evn) => {
            var theEvent = evn || window.event;
            var key = theEvent.keyCode || theEvent.which;

            if (key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 190 || key == 50 || key == 16) { // Left / Up / Right / Down Arrow, Backspace, Delete keys, Dot //-=chubro=-//
                return;
            }
            if (!/^[1-6]*$/i.test(evn.key.trim()))
                evn.preventDefault();
            if (/^[ ]*$/i.test(evn.key.trim()))
                evn.preventDefault();
        }
        changeVat = () => {
            switch (this.$scope.model.VatType) {
                case 0:
                    this.$scope.model.Vat = 0.18;
                    break;
                default:
                    this.$scope.model.Vat = 0;
                    break;
            }
            this.$scope.dirtyModel.vatText = this.$scope.model.Vat * 100 + "%";
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            if (!this.$scope.model.IntCodeID || !this.$scope.model.Name) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ სავალდებულო ველები!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model;

            if (model.Tax1 > 0) {
                model.Tax1 = model.Tax1 / 100;
            }

            if (this.$scope.codeIDmodel.IntCodeID == undefined) {
                this.dictionaryService.saveIntCode(model).then(result => {
                    if (result.Result !== undefined) {

                        this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$uibModalInstance.close(true);
                    }
                });
            }
            else {
                this.dictionaryService.updateIntCode(model).then(res => {
                    if (res.Result !== undefined) {
                        this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$uibModalInstance.close(true);
                    }
                    return " ";
                });
            }
        }
    }
}