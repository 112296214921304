/// <reference path="../_reference.ts"/>

module Apex.Dictionary {
    export class Currency implements Account.Interfaces.ICurrency {
        private static _fields: Array<IColumnWithTranstale> = new Array<IColumnWithTranstale>(
            { name: "LDefault", type:"boolean", titleTranslated: "Default" },
            { name: "CurrencyID", type:"number", titleTranslated: "code" },
            { name: "Code", type:"string", titleTranslated: "currencyCode" },
            { name: "Name", type:"string", titleTranslated: "nameCaption" },
            { name: "NameEn", type:"string", titleTranslated: "nameCaptionEn" },
            { name: "ReCalc", type:"boolean", titleTranslated: "recalc" },
            { name: "LastDate", type:"Date", titleTranslated: "LastDate" },
            { name: "Rate", type:"Price4", titleTranslated: "rate" },
            { name: "Spend", type:"boolean", titleTranslated: "Spend" }
            //{ name: "CurType", type:"number", titleTranslated: "" },
            //{ name: "CurrencyCnt", type:"number", titleTranslated: "" }
            );

        public static GetFields(): Array<IColumnWithTranstale> {
            return this._fields;
        }
    }

    export interface IColumnWithTranstale {
        name: string;
        type:string;
        titleTranslated?: string;
    }

}

