/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Prodpp extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', Globals.translate
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $translate) {
            super(arguments, Prodpp.$inject);
            $scope.activeMode = true,
                $scope.model = {
                    selectedProdPp: {}
                }
            $scope.components = {
                prodppGrid: this.initProdppGrid(),
                events: {
                    add: this.add,
                    edit: this.edit,
                    delete: this.delete,
                    refresh: () => {
                        $scope.components.showRefreshInfo = false;
                        $scope.components.prodppGrid.gridView.showLoading();
                        $scope.components.prodppGrid.gridView.refresh();
                    },
                    openPriceImportModal: this.openPriceImportModal,
                    openProdPPImportModal: this.openProdPPImportModal
                },
                showRefreshInfo: true
            }
            this.onCompanyChange = (newCompany, oldCompany) => {
                $scope.components.events.refresh();
            }
        }
        add = () => {
            // this.$scope.model.selectedProdPp = {};
            // var data = {};
            this.openModal({});
        }

        delete = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                this.dictionaryService.deleteProdpp(this.$scope.model.selectedProdPp.ProdPPId).then((result: any) => {
                    if (!!result.statusCode) {
                        // toaster.pop('error', "შეცდომა!", translate.instant('cannotDelete'));
                        return;
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        this.$scope.components.events.refresh();
                    }
                });
            });

        }

        edit = () => {
            this.dictionaryService.getGoodInfo(this.$scope.model.selectedProdPp.ProdPPId).then(a => {
                this.openModal(a.Result);
            });
        }
        openModal = (data) => {
            var modalSize = Core.Utils.ModalSize.Medium;
            var dlg = Core.Utils.ModalForm.openModalForm("prodpp.modal.html", "prodppModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {
                    this.$scope.components.events.refresh();
                    // this.$scope.model.selectedProdPp = {};
                }
            });
        }

        openPriceImportModal = () => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("priceImport.modal.html", PriceImportModal.getName(), {}, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {

                }
            });
        }

        openProdPPImportModal = () => {
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("prodppImport.modal.html", ProdPPImportModal.getName(), {}, modalSize);
            dlg.result.then(result => {
                if (typeof result !== "undefined") {

                }
            });
        }

        initProdppGrid = () => {
            var dataSourceModel = {
                id: "Products_id",
                fields: {
                    BCode: { type: "string" },
                    InCode: { type: "string" },
                    ProductsNu: { type: "string" },
                    ProducerNu: { type: "string" },
                    CategoryNu: { type: "string" },
                    PPCatNu: { type: "string" },
                    Unit: { type: "string" },
                    PriceGR: { type: "number" },
                    PriceGT: { type: "number" },
                    MDTrade: { type: "number" },
                    MDRetail: { type: "number" },
                    Spend: { type: "boolean" }
                }
            }

            var grid: Components.IApxGrid = {
                columns: [
                    { field: "BCode", titleTranslated: "barcode", width: 150 },
                    { field: "InCode", titleTranslated: "inCode", width: 150 },
                    { field: "ProductsNu", titleTranslated: "goods", width: 300 },
                    { field: "ProducerNu", titleTranslated: "producer", width: 250 },
                    { field: "CategoryNu", titleTranslated: "categoryName", width: 250 },
                    { field: "PPCatNu", titleTranslated: "type", width: 250 },
                    { field: "Unit", titleTranslated: "unit", width: 130 },
                    { field: "PriceGR", titleTranslated: "priceGR", format: Core.Kendo.Format.Price4, width: 150 },
                    { field: "PriceGT", titleTranslated: "priceGT", format: Core.Kendo.Format.Price4, width: 150 },
                    { field: "MDTrade", titleTranslated: "MDTrade", format: Core.Kendo.Format.Percent, width: 150 },
                    { field: "MDRetail", titleTranslated: "MDRetail", format: Core.Kendo.Format.Percent, width: 150 },
                    {
                        field: "Spend", titleTranslated: "Spend", width: 90,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: this.$translate.instant('isSelected') },
                                    { value: 0, text: this.$translate.instant('isNotSelected') }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",

                            })
                        },
                        template: "<div ng-if='dataItem.Spend' class='text-center'><i class='fa fa-check text-muted'></i></div> <span ng-if='!dataItem.Spend'></span>"
                    },

                ],
                settings: {
                    pageable: true,
                    autoBind: false
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.model.selectedProdPp = grid.gridView.selectedItem();
                            });


                            var selectedRow: any = grid.grid.dataItem(grid.grid.select());
                            if (selectedRow != null && selectedRow != undefined) {
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.activeMode = false);
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.activeMode = false);
                            }
                            else {
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.activeMode = true);
                                Core.Utils.Helper.safeApply(this.$scope, this.$scope.activeMode = true);
                            }
                        }
                    }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.dictionaryService.goodsQueryUrl, dataSourceModel)
            };
            return grid;
        }
    };





    export interface ProdppModalScope extends angular.IScope {
        model: {
            Data?: any
        };
        components: {
            categoriesLookUp: Components.IApxLookup
            manufacturesLookUp: Components.IApxLookup,
            prodTypesLookUp: Components.IApxLookup,
            exciseGoodsLookUp: Components.IApxLookup,
            brandsLookUp: Components.IApxLookup,
            bcodesGrid: Apex.Components.IApxGrid,
            unitLookUp: Components.IApxLookup,
            vatTypeDS: any
        };
        events: {
            save: Function,
            closeForm: Function
        }
        setForm: Function,
        changeWeightType: Function,
        getProducerCountry: Function,
        addBCode: Function,
        deleteBCode: Function,
        changeVat: Function,
        clicked: Function,
        dirtyModel: any,
        bCodeText: string;
        formName: string,
        selectedProdppBCode: any,
        formScope: any,
        disableMof: boolean,
        activeTabMode: boolean
    }
    export class ProdppModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'dictionaryService', Globals.modalInstance, Globals.toaster, Globals.http, '$timeout'
        ];
        constructor(private $scope: ProdppModalScope, data: any, private dictionaryService: Services.Dictionary,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $timeout) {
            super(arguments, ProdppModal.$inject);
            $scope.setForm = (formScope) => {
                $timeout(() => {
                    $scope.formScope = formScope;
                    $scope.$apply();
                });
            };
            $scope.selectedProdppBCode = {};
            $scope.activeTabMode = false;
            $scope.bCodeText = "";
            $scope.components = {
                categoriesLookUp: this.initCategory(),
                manufacturesLookUp: this.initProducer(),
                prodTypesLookUp: this.initProdTypes(),
                exciseGoodsLookUp: this.initExciseGoods(),
                brandsLookUp: this.initBrands(),
                bcodesGrid: this.initBcodesGrid(),
                unitLookUp: this.initUnitLookUp(),
                vatTypeDS: [{ val: 0, title: "ჩვეულებრივი" }, { val: 1, title: "ნულოვანი" }, { val: 2, title: "დაუბეგრავი" }],
            },
            
                $scope.events = {
                    save: this.save,
                    closeForm: this.closeForm
                }
                
            data.BarCodes=data.BarCodes==undefined?[]:data.BarCodes; 
            
            $scope.model = {
                Data: data
            }; 
            $scope.changeWeightType = (typeNumber) => {
                this.$scope.model.Data.ByWeight = typeNumber;
                this.changeByWeight();
            }

            $scope.dirtyModel = {
                vatText: '',
                any: false,
                pluWeigh: false,
                pluCount: false,
                wightPcs: false

            }
            $scope.getProducerCountry = () => {
                return $scope.model.Data.Producer.Country.CountryNu || $scope.model.Data.Producer.ProducerCountry;
            }
            $scope.changeVat = this.changeVat;
            $scope.addBCode = this.addBCode;
            $scope.deleteBCode = this.deleteBCode;
            this.changeByWeight();

            $scope.clicked = () => {
                alert("Clicked on: ");
            };

            $timeout(() => {
                 
                if (!!$scope.model.Data.ID) {
                    $scope.formName = "საქონლის რედაქტირება";
                    $scope.model.Data.UnitLookup = {
                        UnitId: $scope.model.Data.UnitId,
                        UnitNu: $scope.model.Data.Unit
                    };
                    this.$scope.activeTabMode = true;
                } else {
                    $scope.formName = "საქონლის რეგისტრაცია";
                    this.$scope.activeTabMode = false;
                    $scope.changeWeightType(0);
                    $scope.model.Data.PackCount = 1;
                    $scope.model.Data.PackBCount = 1;
                    $scope.model.Data.NameEn = "";

                    this.$scope.model.Data.VatType = 0; 
                }
                this.changeVat();
            });

        }

        deleteBCode = () => {
            alert(1);
            var bCodes = this.$scope.model.Data.BarCodes;
            var selectedbCode = this.$scope.components.bcodesGrid.gridView.selectedItem();
            if (!!selectedbCode) {
                bCodes = _.without(bCodes, _.findWhere(bCodes, {
                    BCode: selectedbCode.BCode
                }));
                this.$scope.components.bcodesGrid.gridView.refresh();
                this.$scope.model.Data.bCodeText = "";
            }
        }

        addBCode = () => {
            var bCodes = this.$scope.model.Data.BarCodes;
            bCodes.push({ BCode: this.$scope.model.Data.bCodeText });
            this.$scope.components.bcodesGrid.gridView.refresh();
            this.$scope.model.Data.bCodeText = "";
        }

        changeVat = () => {
            switch (this.$scope.model.Data.VatType) {
                case 0:
                    this.$scope.model.Data.Vat = 0.18;
                    break;
                default:
                    this.$scope.model.Data.Vat = 0;
                    break;
            }
            this.$scope.dirtyModel.vatText = this.$scope.model.Data.Vat * 100 + "%"; 

        }
        changeByWeight() {
            this.$scope.dirtyModel.any = false;
            this.$scope.dirtyModel.pluWeigh = false;
            this.$scope.dirtyModel.pluCount = false;
            this.$scope.dirtyModel.wightPcs = false;
            switch (this.$scope.model.Data.ByWeight) {
                case 0:
                    this.$scope.dirtyModel.any = true;
                    break;
                case 1:
                    this.$scope.dirtyModel.pluWeigh = true;
                    break;
                case 2:
                    this.$scope.dirtyModel.pluCount = true;
                    break;
                case 3:
                    this.$scope.dirtyModel.wightPcs = true;
                    break;

            }
        }

        initBrands = () => {
            var dataSource = Brands.brandsDataSource(50);
            return {
                valueText: 'Name',
                columns: [
                    { field: 'Abbreviation', titleTranslated: 'abbreviation', width: 200 },
                    { field: 'Name', titleTranslated: 'nameCaption' }
                ],
                dataSource: dataSource
            }
        }

        initUnitLookUp = () => {
            var dataSource = Units.unitsDataSource(50); 
            return {
                valueText: 'UnitNu',
                columns: [{ field: 'UnitNu', titleTranslated: 'nameCaption', width: 100 }],
                dataSource: dataSource
            }
        }

        initCategory = () => {
            var dataSource = Categories.categoriesDataSource(50);
            return {
                valueText: 'CategoryName',
                columns: [{ field: 'CategoryName', titleTranslated: 'nameCaption', width: 100 }],
                dataSource: dataSource
            }
        }
        initExciseGoods = () => {
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getExiceGoods();
                        fn.then(result => {
                            options.success(result.Result);
                            this.$scope.disableMof = false;
                        }, error => {
                            if (error.success === false) {
                                this.$scope.disableMof = true;
                                options.success([]);
                            }
                        });
                    }
                },
                schema: {
                    model: {
                        fields: {
                            ExciseID: { type: "number" },
                            Name: { type: "string" },
                            GoodCode: { type: "string" },
                            Measurement: { type: "string" },
                            Excise: { type: "number" }

                        }
                    }
                },
                pageSize: 50
            });
            return {
                valueText: 'Name',
                columns: [
                    { field: 'ExciseID', titleTranslated: 'ID', width: 40, format: Core.Kendo.Format.Number },
                    { field: 'Name', titleTranslated: 'nameCaption' },
                    { field: 'GoodCode', titleTranslated: 'code', width: 90 },
                    { field: 'Measurement', titleTranslated: 'unit', width: 80 },
                    { field: 'Excise', titleTranslated: 'exice', width: 60, format: Core.Kendo.Format.Price2 }
                ],
                dataSource: dataSource
            }
        }
        initProducer = () => {
            var dataSource = Manufacture.manufacturesDataSource(50);
            return {
                valueText: 'ProducerName',
                columns: [{ field: 'ProducerName', titleTranslated: 'nameCaption', width: 100 }],
                dataSource: dataSource
            }
        }
        initProdTypes = () => {
            var dataSource = Types.prodTypeDataSource(50);
            return {
                valueText: 'Name',
                columns: [{ field: 'Name', titleTranslated: 'nameCaption', width: 100 }],
                dataSource: dataSource
            }
        }
        initBcodesGrid = () => {
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        options.success(this.$scope.model.Data.BarCodes);
                    }
                }
            });


            var grid: Components.IApxGrid = {
                columns: [
                    { field: "BCode", titleTranslated: "barcode" }
                ],
                settings: {
                    height: 340
                },
                dataSource: dataSource
            };
            return grid;
        }
        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        save = () => {
            var modalForm = this.$scope.formScope.modal;
            if (modalForm.$invalid) {
                this.toaster.pop('error', 'შეცდომა!', 'გთხოვთ შეავსოთ ყველა სავალდებულო ველი!');
                return;
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var model = this.$scope.model.Data;
            model.CategoryID = model.Category.CategoryId || model.Category.CategoryID;
            model.ProducerID = model.Producer.ProducerID;
            model.PPCatID = model.Type.ID;
            model.Unit = model.UnitLookup.UnitNu;
            model.UnitId = model.UnitLookup.UnitId;
            model.WholesalePrice = model.PriceGT;
            model.RetailPrice = model.PriceGR;

            if (!!model.ExtendedInfo && !!model.ExtendedInfo.Brand) {
                model.ExtendedInfo.BrandID = model.ExtendedInfo.Brand.BrandId;
            }
            var exInfo = model.ExtendedInfo;

            // model.ID = model.ProdppID
            if (!!model.HCategory)
                model.PCatID = model.HCategory.ID;

            var priceInfo = {
                RetailPrice: model.PriceGR,
                WholesalePrice: model.PriceGT
            }

            this.dictionaryService.saveProdpp(model, exInfo, priceInfo).then(result => {
                if (result.ProdppID !== undefined) {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    this.$uibModalInstance.close(true);
                }
                return " ";
            });
        }
    }
}