/// <reference path="../_reference.ts"/>
module Apex.Dictionary {

    export interface IApxGridEx {
        axGridFocuseRowChange(e?: kendo.ui.GridEvent): void;
        axGridFocuseRowDelete(e?: kendo.ui.GridEvent): void;
        axGridFocuseRowEdit(e?: kendo.ui.GridEvent): void;
    }

    export class ApexGridEx implements IApxGridEx {
        private apxGrid: Components.IApxGrid;


        public templates: ITemplateEx = {
            BooleanTypeTemplates: '<div class="checkbox checkbox-styled checkbox-danger" ' +
            'style="width:20px; height:5px"><input type="checkbox" ng-checked="{{::dataItem.{0}}}" disabled> ' +
            '<span ng-if="dataItem.{0}"> </span></div>',
            DataTypeTemplate: ''
        };

        public GetApexGrid(fields: Array<IColumnWithTranstale>, Id: string): Components.IApxGrid {
            this.apxGrid = {
                columns: this.generateGridColumns(fields),
                dataSource: this.initDataSource(Id),
                templates: this.addDefaultTemplates(fields),
                gridEvents: this.getGridEvents()
            }
            return this.apxGrid;
        }


        private generateGridColumns(columns: Array<IColumnWithTranstale>): any {
            var axColumns: Array<Core.Interfaces.IGridColumn> = [];
            _.forEach(columns, (item: IColumnWithTranstale) => {
                axColumns.push({ field: item.name, format: this.getKendoFormatType(item.type), titleTranslated: item.titleTranslated, width: 80 });
            });
            return axColumns;
        }

        private initDataSource(id: string): kendo.data.DataSource {
            var dataSource = new kendo.data.DataSource(
                {
                    data: [].toDatasource(),
                    schema:
                    {
                        model:
                        {
                            id: id,
                            fields:
                            {

                            }

                        }
                    }
                }
            );
            return dataSource;
        }

        private getKendoFormatType(type: string): Core.Kendo.Format {
            var format: Core.Kendo.Format;
            switch (type) {
                case "numbe":
                    format = Core.Kendo.Format.Number;
                    break;
                case "Date":
                    format = Core.Kendo.Format.Date;
                    break;
                case "DateTime":
                    format = Core.Kendo.Format.DateTime;
                    break;
                case "Price4":
                    format = Core.Kendo.Format.Price4;
                    break;
                case "Price2":
                    format = Core.Kendo.Format.Price2;
                    break;
                case "Percent":
                    format = Core.Kendo.Format.Percent;
                    break;
                default:

                    format = Core.Kendo.Format.None;
            }
            return format;
        }

        private addDefaultTemplates(fields: Array<IColumnWithTranstale>): Array<Core.Interfaces.IGridColumnTemplate> {
            var currencyGridTempaltes = [];
            var boolTypeProperties = _.where(fields, { type: "boolean" });
            this.setTemplateForBoolean(boolTypeProperties, currencyGridTempaltes);
            return currencyGridTempaltes;
        }


        private setTemplateForBoolean(fields: Array<IColumnWithTranstale>, gridTempaltes: Array<Core.Interfaces.IGridColumnTemplate>): Array<Core.Interfaces.IGridColumnTemplate> {
            //var fieldsTemplate = [];
            if (fields != null && fields.length > 0) {
                for (var i = 0; i < fields.length; i++) {
                    // Iterate over numeric indexes from 0 to 5, as everyone expects.
                    gridTempaltes.push({
                        fieldName: fields[i].name,
                        template: this.stringFormat(this.templates.BooleanTypeTemplates, fields[i].name)
                    });
                }
            }
            return gridTempaltes;
        }


        /****************** Events ****************/
        public axGridFocuseRowChange;
        public axGridFocuseRowDelete;
        public axGridFocuseRowEdit;

        private getGridEvents(): Array<Apex.Components.IEvent> {
            var events: Array<Apex.Components.IEvent> = [];
            if (this.axGridFocuseRowChange !== undefined)
                events.push({ eType: Components.EventType.Change, eFunction: this.axGridFocuseRowChange });
            if (this.axGridFocuseRowDelete !== undefined)
                events.push({ eType: Components.EventType.Delete, eFunction: this.axGridFocuseRowDelete });
            if (this.axGridFocuseRowEdit !== undefined)
                events.push({ eType: Components.EventType.Edit, eFunction: this.axGridFocuseRowEdit });
            return events;
        }

        /******************************************/



        /*************************** string Format *****************************/
        private stringFormat(source: string, target: any): string {
            if (source != null && source.trim().length) {
                var splitedValue = source.split('dataItem.{0}');
                for (var i = 0; i < splitedValue.length - 1; i++) {
                    source = source.replace('dataItem.{0}', 'dataItem.' + target.toString());
                }
            }
            return source;
        }
        /*************************************************************************/

    }

    export interface ITemplateEx {
        BooleanTypeTemplates?: string;
        DataTypeTemplate?: string;
    }
}