/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Controllers {
    export class Currency extends Core.Controller {
        public static $inject = [
            Globals.scope, 'dictionaryService', '$timeout'
        ];
        constructor(private $scope: any, private dictionaryService: Dictionary.Services.Dictionary, private $timeout) {
            super(arguments, Currency.$inject);
            $scope.currencies = {
                showRefreshInfo: true,
                dateBetween: {
                },
                selectedCurrency: null
            }
            $scope.components = {
                currencyGrid: this.initCurrencyGrid(),
                rateGrid: this.initRateGrid(),
                events: {
                    refresh: () => {
                        $scope.components.currencyGrid.gridView.showLoading();
                        $scope.components.rateGrid.gridView.showLoading();
                        $scope.currencies.showRefreshInfo = false;
                        $timeout(() => {
                            $scope.components.currencyGrid.gridView.refresh();
                            $scope.components.rateGrid.gridView.refresh();
                        });
                    }
                }
            }

            $scope.$watch('currencies.dateBetween', () => {
                $scope.currencies.showRefreshInfo = true;
            }, true);


            this.onCompanyChange = (newCompany, oldCompany) => {
                $scope.components.rateGrid.grid.dataSource.data([]);
                $scope.components.events.refresh();
            }

        }
        initCurrencyDS = () => {
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        var fn: any = this.dictionaryService.getCurrencies();
                        fn.then(result => {
                            options.success(result.Result);
                        });
                    }
                },
                schema: {
                    model: {
                        id: "CurrencyID",
                        fields: {
                            CurrencyID: { type: "string" },
                            Code: { type: "string" },
                            Name: { type: "string" }
                        }
                    }
                }
            });

            return dataSource;
        }
        initCurrencyGrid = () => {
            var currencyGrid: Components.IApxGrid = {
                columns: this.currencyColumns,
                dataSource: this.initCurrencyDS(),
                settings: {
                    autoBind: false
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: (e) => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.currencies.selectedCurrency = currencyGrid.gridView.selectedItem();
                                this.$scope.components.rateGrid.gridView.refresh();
                            });
                        }
                    }
                ]
            };
            return currencyGrid;
        }
        initRatesDS = () => {
            var dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        var model = this.$scope.currencies;
                        var currencyID = model.selectedCurrency !== null ? model.selectedCurrency.CurrencyID : undefined;
                        if (currencyID) {
                            var fn: any = this.dictionaryService.getCurrenciesRates(currencyID, Core.Utils.Helper.toLocalTimeISO(model.dateBetween.fromDate), Core.Utils.Helper.toLocalTimeISO(model.dateBetween.toDate));;
                            fn.then(result => {
                                options.success(result.Result || []);
                            });
                        } else {
                            options.success([]);
                        }
                        this.$scope.currencies.showRefreshInfo = false;
                    }
                },
                schema: {
                    model: {
                        fields: {
                            RateDate: { type: "date", format: "{0:dd/MM/yyyy}" },
                            RateBank: { type: "number" }
                        }
                    }
                }
                // sort: { field: "RateDate", dir: "desc" }
            });
            return dataSource;
        }
        initRateGrid = () => {
            var ratesGrid: Components.IApxGrid = {
                columns: this.ratesColumns,
                dataSource: this.initRatesDS(),
                settings: { autoBind: false }
            };
            return ratesGrid;
        }

        getRates = () => {

        }


        ratesColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'RateDate', titleTranslated: 'DATE', format: Core.Kendo.Format.Date, },
            { field: 'RateBank', titleTranslated: 'rate', format: Core.Kendo.Format.Price4 }
        ];

        currencyColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'CurrencyID', titleTranslated: 'code', width: 80 },
            { field: 'Code', titleTranslated: 'currencyCode', width: 120 },
            { field: 'Name', titleTranslated: 'nameCaption' }
        ];
    };
}