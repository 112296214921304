/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Interfaces {
    'use strict';
    export  interface  CurrencyEx extends Apex.Account.Interfaces.ICurrency {
        NameEn?: string;
        LastDate : Date;
        Rate :number;
        Spend : boolean;
        CurrencyCnt :number;
        Currency_N? : string;
        LDefault :boolean;
    }

}