/// <reference path="./../_reference.ts"/>
module Apex.Dictionary.Interfaces {
    'use strict';
    export interface IRecipeCost {
        MBcode?: string;
        Material?: string;
        Producer?: string;
        Unit?: string;
        UnitCost?: number;
        MCount?: number;
        BCount?: number;
        Cost?: number;
        RecipeNote?: string;
        MProdPPId?: string;
        InCode?: string;
        RecipeId?: string;
        CanExpand?: boolean;
        PackCount?: number;
        ProdPPId?: string;
        BCode?: string;
    } 
}